import useSWR, {mutate} from 'swr';
import API_SERVER from 'net/urls';
import {newFetcher, FetchedData, FetchedNewData} from 'net/fetcher';

export interface Team{
  _id:string,
  teamName:string,
  orgId:string,
  teamid:string
}

export interface Asset{
  name:string,
  accessTime:number,
  size:number, //bytes
  _id:string,
}


export function createTeam(body:any){
  const url = `${API_SERVER}/api/teams`;

  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate(`/api/teams`);
  });
}

export function addUserToTeam(body:any){
  const url = `${API_SERVER}/api/teams/user`;

  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate(`/api/teams/user`);
  });
}


export function depositAssetLocker(teamId:any,roomId:any,fileName:any){
  const url = `${API_SERVER}/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/deposit`;

  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate(`/api/teams/user`);
  });
}

export function uploadAsset(teamId:any,file:File){
  const formdata = new FormData();
  formdata.append("", file, file.name);

  const requestOptions:RequestInit = {
    method: 'POST',
    body: formdata,
    credentials: 'include'
  };

  const url = API_SERVER + "/api/teams/" + teamId + "/addAsset";

  return fetch(url, requestOptions)
	.then(res=>res.json())
  .then( jsonBody =>{
    mutate(`/api/teams/${teamId}/addAsset`); //mark cache invalid
		return jsonBody
  })

}

export function uploadDefaultAsset(teamId:any,assetData:any){

  const requestOptions:RequestInit = {
    method: 'POST',
    body: JSON.stringify(assetData),
    credentials: 'include'
  };

  const url = API_SERVER + "/api/teams/" + teamId + "/addRdpAsset";

  return fetch(url, requestOptions)
	.then(res=>res.json())
  .then( jsonBody =>{
    mutate(`/api/teams/${teamId}/addRdpAsset`); //mark cache invalid
		return jsonBody
  })

}

export function deleteTeamById(id:string){
  const url = `${API_SERVER}/api/teams/${id}`;
  const options:RequestInit = {
    method:'DELETE',
    credentials:'include'
  }
  return fetch(url, options)
  .then(()=>{
    mutate('/api/teams');
  })
}

export function deleteAssetFromTeam(teamid:string, fileName:string){
  const url = `${API_SERVER}/api/teams/${teamid}/removeAsset/${fileName}`;
  const options:RequestInit = {
    method:'DELETE',
    credentials:'include'
  }
  return fetch(url, options)
  .then(()=>{
    mutate('/api/teams');
  })
}

export function useTeams(body: any):FetchedNewData<Team[]>{
  const {data, error}= useSWR(['/api/teams', body], newFetcher);
  return {
    newData:data,
    newIsLoading: !error && !data,
    newIsError: error,
  }
}

/*export function useTeams(body: any):FetchedNewData<Team>{
  const {data, error}= useSWR('/api/teams', body, getter);
  return {
    newData:data,
    newIsLoading: !error && !data,
    newIsError: error,
  }
}*/

export function updateTeam(roomid:string, body:EditRequestBody){
  const url = getTeamUrl(roomid);
  const reqOptions:RequestInit = {
    method: 'PUT',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate('/api/rooms');
    mutate('/api/me/rooms');
    mutate(`/api/rooms/${roomid}`);
  });
}

export interface EditRequestBody{
  name: string,
  description: string,
  access:string,
}

function getTeamUrl(id:string){
  return (API_SERVER
    + '/api/teams/'
    + id);
}
