import {
  Flex,
  View,
  Heading,
  Text,
  Content,
  TextField
} from '@adobe/react-spectrum';
import React from 'react';
import Settings from '@spectrum-icons/workflow/Settings';
import {Grid, GridRow, GridCell} from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import '@rmwc/grid/styles';
import Upload from '@spectrum-icons/illustrations/Upload';
import ChangeProfilePhoto from 'components/ChangeProfilePhoto';
import PageHeader from 'components/pageheader/PageHeader';
import {EditMyUserForm} from 'components/EditUser';
import EditPasswordButton from 'components/buttons/EditPasswordButton';
import {ThemeSwitcher} from 'aesthetic/ColorSwitcher';
import {useMyUser, User} from 'net/user';
interface SettingsPageProps{
  userName: any,
  userEmail: any,
  userRole: any,
  userId: any,
  userOrgId: any,
}

export default function SettingsPage(){
  const {data, isError, isLoading} = useMyUser();
  let [userRole, setUserRole] = React.useState(true);
  console.log(data);
  

  return (
    <Flex direction="column" gap="size-200">
      <Flex alignItems="center" gap="size-200">
        <Heading level={1} UNSAFE_style={{marginLeft: '20px'}}>Settings</Heading>
      </Flex>
      <div style={{backgroundColor: '#232323', paddingTop: '20px', paddingBottom: '20px', borderRadius: '20px', marginLeft: '20px', marginRight: '20px'}}>
        <Grid style={{paddingLeft: '20%', paddingRight: '20%'}}>
          <GridRow>
            <GridCell align='middle' span={6}>
              <ChangeProfilePhoto name={data.name} role={data.role} />
            </GridCell>
            <GridCell span={6}>
              <EditMyUserForm data={data} isError={isError} isLoading={isLoading} />
            </GridCell>
          </GridRow>
        </Grid>
      </div>
    </Flex>
  );
}
