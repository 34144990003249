import {
	TextField,
	Form,
	Button,
	Dialog,
	Content,
	Divider,
	RadioGroup,
	Heading,
	Radio,
	Text,
} from '@adobe/react-spectrum';
import React from 'react';
import {deleteTeamById} from 'net/team';
import AssetListForTeams from 'components/lists/AssetListForTeams';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import '@rmwc/grid/styles';
import {useMyUser, User} from 'net/user';
import Close from '@spectrum-icons/workflow/Close';

interface ViewTeamsDialogProps{
  close:()=>void
}

export default function ViewTeamsDialog({close}:ViewTeamsDialogProps){
	const {data, isLoading, isError} = useMyUser();
	console.log(data);
	
	
  return (
    <Dialog UNSAFE_className="threeDataDialBox">
			<Heading>View Teams & Assets</Heading>
      <Content>
        {
					data.teams.map((team:any) => {
						console.log(team);
						
						return (
							<>
								<Heading>Team Name</Heading>
								<Content>
									<Text>{team.teamName}</Text>
									<Button UNSAFE_style={{marginLeft: 10}} variant="primary" onPress={() => {
										console.log('deleted team:', team.teamId);
										
										deleteTeamById(team.teamId)
										.then(() => {
                                            window.location.reload();
                                        })
										.catch(err=>console.error(err))
									}}><Close /></Button>
									{
										<AssetListForTeams teamid={team.teamId} />
									}
								</Content>
							<br />
							<Divider size="M" />
						</>
					)
				})
			}
		</Content>
		</Dialog>
	);
}
