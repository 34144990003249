import {defaultTheme as dt} from '@adobe/react-spectrum';
import styles from 'aesthetic/T3DataStyles.module.css';
import  data from 'aesthetic/3dataCSS/3dataMain.module.scss';
export let T3DataStyles = {
  global: dt.global,
  global3data: data,
  light: dt.light,
  dark: styles,
  medium: dt.medium,
  large: dt.large,
};
