import{
  ActionButton,
  DialogTrigger,
} from '@adobe/react-spectrum';
import {User} from 'net/user';
import RemoveCircle from '@spectrum-icons/workflow/RemoveCircle';
import DeleteUserDialog from 'components/modals/DeleteUserDialog';

interface DeleteUserButtonProps{
  user:User
}
export default function DeleteUserButton({user}:DeleteUserButtonProps){
  return (
    <DialogTrigger>
      <ActionButton>
        <RemoveCircle size="S"/>
      </ActionButton>
      <DeleteUserDialog user={user}/>
    </DialogTrigger>

  );
}
