/**
 * the navbar on the side
 * uses react-router for client-side routing
 * see also src/App.tsx
 */
// import{
//   Flex,
//   Button,
// }from '@adobe/react-spectrum';

import { DrawerContent } from '@rmwc/drawer';

import { List, ListItem, ListItemGraphic } from '@rmwc/list';
import '@rmwc/list/styles';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import {
   useMatch as useRouteMatch,
   useNavigate as useHistory,
} from 'react-router-dom';

import { useMyUser } from 'net/user';

import './navigation.scss';

export default function NavBar() {
   const { data, isLoading, isError } = useMyUser();

   const pathInfoList = data.role === 'super-admin' ? (
      [
         {
            name: "Home",
            icon: "assets/home.svg",
            path: "/"
         },
         {
            name: "Teams",
            icon: "assets/teams.svg",
            path: "/teams",
         },
         {
            name: "Users",
            icon: "assets/user.svg",
            path: "/users"
         },
         {
            name: "Settings",
            icon: "assets/settings.svg",
            path: "/settings",
         },
         {
            name: "Provision",
            icon: "assets/provision.svg",
            path: "/provision",
         }
      ]
   ) : data.role === 'local-admin' ? (
      [
         {
            name: "Home",
            icon: "assets/home.svg",
            path: "/"
         },
         {
            name: "Teams",
            icon: "assets/teams.svg",
            path: "/teams",
         },
         {
            name: "Users",
            icon: "assets/user.svg",
            path: "/users"
         },
         {
            name: "Settings",
            icon: "assets/settings.svg",
            path: "/settings",
         },
      ]
   ) : (
      []
   );

   return (
      <DrawerContent>
         <List onAction={(e) => console.log(e)}>
            {pathInfoList.map(pathInfo => (
               <MenuLink key={pathInfo.path} name={pathInfo.name} path={pathInfo.path} icon={pathInfo.icon} />
            ))}
         </List>
      </DrawerContent>
   );
}
interface MenuLinkProps {
   name: string,
   path: string,
   icon: string,
}

function MenuLink({ name, path, icon }: MenuLinkProps) {
   const match = (useRouteMatch as any)({
      path: path,
      exact: true,
   });
   // const history: any = useHistory();
   const history = {
      push: useHistory(),
   }
   const hasMatched = match ? true : false;
   return (
      // <Button
      //   variant={hasMatched ? "cta" : "secondary"}
      //   isQuiet={!hasMatched}
      //   autoFocus={false}
      //   onPress={()=>{
      //     history.push(path)
      //   }}
      //   UNSAFE_style={{borderRadius:"0px"}}
      // >

      // </Button>
      //<button onClick = {()=>{ history.push(path)}}>
      <ListItem style={{ border: 'transparent' }} onClick={() => { history.push(path) }}>
         {/* <Icon icon={{ icon: 'favorite', size: 'xsmall' }} /> */}
         <Icon
            icon={{
               icon: `${icon}`,
               strategy: 'url'
            }}
            style={{ marginRight: '30px' }}
         />
         {name}
      </ListItem>
      //</button> 

   );
}
