import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid'
import {GetCalendar, Calendar} from 'net/calendar'
import {useMyUser} from 'net/user'
import './Calendar.scss'
import {Dialog} from '@adobe/react-spectrum'
import UnloadedModal from 'components/modals/Unloaded'

interface CalUser{
	userId:any,
}

export default function FullCal({userId}:CalUser) {
    const {data, isLoading, isError} = GetCalendar(userId);

    if(isError) return <Dialog>"Error loading calendar"</Dialog>;
    
    return (
        // <FullCalendar
        //     plugins={[ dayGridPlugin ]}
        //     initialView="dayGridMonth"
        //     events={eventBuilder(data)}
            // customButtons={{
            //     addEventButton: {
            //         text: 'add event',
            //         click: function() {
            //             alert('clicked the custom button!');
            //         },
            //     },
            // }}
        //     headerToolbar={{
        //         left: 'title',
        //         center: 'addEventButton',
        //         right: 'today prev,next'
        //     }}
        // />
        <FullCalendar
            plugins={[ dayGridPlugin, rrulePlugin ]}
            initialView="dayGridMonth"
            events={eventBuilder(data)}
            headerToolbar={{
                left: 'title',
                right: 'today prev,next'
            }}
        />
    )
}

function eventBuilder(events:any) {
    let arr:any = [];

    if (events) {
        events.map((event:any) => {
            if (event.when.object === 'timespan' && event.recurrence) {
                let obj = {
                    title: event.title,
                    start: reformatDate(event.when.start_time),
                    end: reformatDate(event.when.end_time),
                    rrule: event.recurrence.rrule[0]
                }
                arr.push(obj);
            }
            if (event.when.object === 'date' && event.recurrence) {
                let obj = {
                    title: event.title,
                    data: event.when.date,
                    rrule: event.recurrence.rrule[0]
                }
                arr.push(obj);
            }
            if (event.when.object === 'timespan' && !event.recurrence) {
                let obj = {
                    title: event.title,
                    start: reformatDate(event.when.start_time),
                    end: reformatDate(event.when.end_time),
                }
                arr.push(obj);
            }
            if (event.when.object === 'date' && !event.recurrence) {
                let obj = {
                    title: event.title,
                    data: event.when.date,
                }
                arr.push(obj);
            }
        })
    }
    return arr;
}

function reformatDate(elDate:any) {
    let date = new Date(elDate * 1000);
    let formattedDate = date.toISOString().split('T')[0];
    
    return formattedDate;
}


