import React from 'react';
import {
  ActionButton,
} from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';
import {deletePermit} from 'net/permits';

interface DeletePermitButtonProps{
  roomid:string,
  permitid:string,
}

export default function DeletePermitButton({roomid, permitid}:DeletePermitButtonProps) {

  return (
      <ActionButton
        onPress={()=>
          deletePermit(roomid, permitid)
            .catch(err=>console.error(err))
        }>
        <Close />
      </ActionButton>
  );
}
