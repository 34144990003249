/**
 * from a dynamic route, get the roomid and show info
 */
import{
  useParams,
} from 'react-router-dom';
import {useRoomWithId} from 'net/rooms';
import {useMyUser} from 'net/user';
import RoomPage from 'pages/r/RoomPage';

interface RoomsPageProps{
  userName: any,
  userEmail: any,
  userRole: any,
  userId: any,
  userOrgId: any,
}
export default function RoomInfoWithId(){
  const params = useParams() as any;
  const {data, isLoading, isError} = useRoomWithId(params.roomId);
  const {data: dataU, isLoading: isLoadingU, isError: isErrorU} = useMyUser();

  // console.log('ROOMID', data);
  
  
  if(isLoading) return <></>
  if(isError) return (
    <>
    </>
  );

  return (
    <RoomPage userData={dataU} roomid={params.roomId} teamid={data.teamId} />
  );
}
