/**
 * list rooms i own or rooms i'm shared with
 */
import React from 'react';
import {
	ProgressBar,
	Heading,
	StatusLight,
	Text,
	Flex,
	Button,
	View,
} from '@adobe/react-spectrum';
import {DataTable, DataTableRow, DataTableHead, DataTableBody, DataTableHeadCell, DataTableCell, DataTableContent} from '@rmwc/data-table';
import '@rmwc/data-table/styles';
import {Checkbox} from '@rmwc/checkbox';
import '@rmwc/checkbox/styles'
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import AssetListForRoom from 'components/lists/AssetListForRoom';
import {useTeamAssets, useMyAssetsTwo, unlinkAsset} from 'net/assets';
import Shop from '@spectrum-icons/workflow/Shop';
import PermitList from 'components/lists/PermitList';
import ShareWithButton from 'components/buttons/ShareWithButton';
import LinkAssetButton from 'components/buttons/LinkAssetButton';
import {useMyUser} from 'net/user';
import {depositAssetLocker} from 'net/team';
import {useRoomWithId} from 'net/rooms';
import {useTeamsWithId, GetMyTeams} from 'net/teams';
import {PLATFORM} from 'net/urls';
import EditRoomButton from 'components/buttons/EditRoomButton';   

interface SpaceDetailsProps{
	userData:any,
}
export default function SpaceDetails({userData}:SpaceDetailsProps){
	const [myAssets, setMyAssets] = React.useState();
	const [checked, setChecked] = React.useState<any>([]);

	const useThisAsset = useMyAssetsTwo(userData.teams[0].teamId, userData._id)

	React.useEffect(() => {
		useThisAsset
		.then(response => {return response})
		.then((data:any) => {
			setMyAssets(data);
		});
	}, []);
	
	
  return (
    <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
      <Heading level={4}>Available Assets</Heading>

			<DataTable
				style={{ height: '300px', width: '100%', backgroundColor: 'transparent', borderLeftColor: 'transparent', borderRightColor: 'transparent', marginBottom: '30px' }}
				stickyRows={1}
			>
				<DataTableContent>
					<DataTableHead>
						<DataTableRow>
							<DataTableHeadCell style={{background: '#232323', color: 'white'}}><Heading level={4}>Name</Heading></DataTableHeadCell>
							<DataTableHeadCell style={{background: '#232323', color: 'white'}}><Heading level={4}>Date Created</Heading></DataTableHeadCell>
							<DataTableHeadCell style={{background: '#232323', color: 'white'}}><Heading level={4}>Size</Heading></DataTableHeadCell>
							<DataTableHeadCell style={{background: '#232323', color: 'white'}}>
								<Heading level={4}>Team</Heading>
							</DataTableHeadCell>
						</DataTableRow>
					</DataTableHead>
					<DataTableBody>
						{
							userData.teams.map((team:any) => {
								if (!team) {
									return (
										<DataTableRow>
											<DataTableCell style={{color: 'white'}}>No assets loaded</DataTableCell>
											<DataTableCell style={{color: 'white'}}>No assets loaded</DataTableCell>
											<DataTableCell style={{color: 'white'}}>No assets loaded</DataTableCell>
											<DataTableCell style={{color: 'white'}}>No assets loaded</DataTableCell>
										</DataTableRow>
									)
								}
								if (team.assigned) {
									return ListAssets(team, checked, setChecked, myAssets)
								}
							})
						}
					</DataTableBody>
				</DataTableContent>
			</DataTable>
			<div>
				<Button
					UNSAFE_style={{marginRight: '5px', marginBottom: '30px'}}
					variant="primary"
					aria-label="Team Name"
					onPress={() => {
						for (const property in checked) {
							if (checked[property].checked) {
								depositAssetLocker(checked[property].teamID, userData._id, checked[property].assetName).then((response) => {
									// window.location.reload();
									return response
								})
								.then((data:any) => {
									console.log('RESPONSE', data);
									
								});
							} else {
								unlinkAsset(checked[property].teamID, userData._id, checked[property].assetName).then(() => {
									// window.location.reload();
								});
							}
						}
					}}
				>
					<Text>Update Assets</Text>
				</Button>
			</div>
    </div>
  );
}

function ListAssets(team:any, checked:any, setChecked:any, myAssets:any) {
	const {data, isLoading, isError} = useTeamAssets(team.teamId);
	
	// create array of my current assets so I can loop through and appropriately apply a check to the list of all assets
	// that are already in the users room
	let arr:any = [];
	if (myAssets) {
		myAssets.data.map((obj:any) => {
			arr.push(obj.fileName);
		})
	}
	
	if (isLoading) { return <><ProgressBar label="Loading…" isIndeterminate /></>}
	if (isError) { return <><StatusLight variant="negative">Couldn't retrieve room assets</StatusLight></>}

	if (data && data.length > 0) {
		return (
			data.map((asset:any, index:any) => {
				return (
					<DataTableRow key={asset._id}>
						<DataTableCell>
							<Checkbox
								style={{color: 'white'}}
								label={asset.name}
								checked={checked[index] ? checked[index].checked : arr.includes(asset.name) ? true : false}
								onChange={(evt:any) => {
									checked[index] = {assetName: asset.name, teamID: team.teamId, checked: evt.currentTarget.checked};
									setChecked({ ...checked });
								}}
							/>
						</DataTableCell>
						<DataTableCell style={{color: 'white'}}>{reformatDate(asset.modifyTime)}</DataTableCell>
						<DataTableCell style={{color: 'white'}}>{Math.round((asset.size/1000000)*100)/100} MB</DataTableCell>
						<DataTableCell>
							<Button
								UNSAFE_style={{marginRight: '5px'}}
								variant="primary"
								aria-label="Team Name"
							>
								<Text>{team.teamName}</Text>
							</Button>
						</DataTableCell>
					</DataTableRow>
				)
			})
		)
	}
}

function reformatDate(elDate:any) {
	let date = new Date(elDate).toLocaleDateString("en-US");
	return date;
}