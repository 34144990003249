import {
  TextField,
  Form,
  Dialog,
  Content,
  RadioGroup,
  Radio,
  Text,
  View,
  Button,
} from '@adobe/react-spectrum';
import React from 'react';
import {createUser, useMyUser} from 'net/user';
import './Modals.scss';
interface CreateUserDialogProps{
  close:()=>void
}

export default function CreateUserDialog({close}:CreateUserDialogProps){
  const {data, isError, isLoading} = useMyUser();

  const emailDomain = data.email.split('@').slice(-1)[0];
  
  
  const [email, setEmail] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [domain, setDomain] = React.useState(`@${emailDomain}`);
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [role, setRole] = React.useState('standard-user');
  const passWarning = React.useMemo(
    () => warnPassword(password), 
    [password]
  );
  const passValid= passWarning ==='';
  const isValidEmail = React.useMemo(
    () =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        `${email}${domain}`
      ),
    [`${email}${domain}`]
  );
   
  return (
    <Dialog UNSAFE_className="threeDataDialBox">
      <Content>
        <Form
          isRequired
        >
          <TextField
            label="Name"
            value={name}
            onChange={setName}
          />
          <View>
            <TextField 
              label="Email"
              value={email}
              onChange={setEmail}
              validationState={isValidEmail ? 'valid' : 'invalid'}
            />
            <TextField 
              label="Email Domain"
              value={domain}
            />
          </View>
          <TextField 
            label="Password"
            type="password"
            value={password}
            onChange={setPassword}
            validationState={passWarning==='' ? 'valid' : 'invalid'}
          />
          <Text>{passWarning}</Text>
          <RadioGroup
            label="Role"
            value={role}
            onChange={setRole}
            orientation="horizontal"
          >
            <Radio value="standard-user">standard-user</Radio>
            <Radio value="local-admin">local-admin</Radio>
            {data.role === "super-admin"
              ? (<Radio value="super-admin">super-admin</Radio>) : (<></>)
            }
          </RadioGroup>
          <Button variant="cta" onPress={()=>{
            if(!passValid || !isValidEmail){
              return;
            }
            
            createUser({name:name, password:password, email:`${email}${domain}`, role:role})
            .then( (response) => {
							if(!response.successful && response.debug === '_LICENSE_EXCEPTION_'){
								setErrorMsg(response.errorMsg) 
							} else if ( !response.successful ) {
								setErrorMsg(response.errorMsg)
							} else {
								close()
							}
						});
          }}
          >
          Submit</Button>
					<Text>{errorMsg}</Text>
        </Form>
      </Content>
    </Dialog>
  );
}
export function warnPassword(password:string){
  if(password.length < 10){
    return "Must be ten characters or longer";
  }
  if(!/[a-z]/.test(password)){
    return "Must contain at least 1 lowercase letter";
  }
  if(!/[A-Z]/.test(password)){
    return "Must contain at least 1 uppercase letter";
  }
  if(!/[0-9]/.test(password)){
    return "Must contain a number";
  }
  //this'll be falsy 
  return '';
}
