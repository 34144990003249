import {
  TextField,
  Form,
  Dialog,
  Content,
  RadioGroup,
  Radio,
  Text,
  Button,
} from '@adobe/react-spectrum';
import React from 'react';
import {createTeam} from 'net/team';
import './Modals.scss';
import {useMyUser} from 'net/user';
interface CreateTeamDialogProps{
  close:()=>void
}

export default function CreateTeamDialog({close}:CreateTeamDialogProps){
  const [orgId, setOrgId] = React.useState('');
  const [teamName, setName] = React.useState('');
  const {data: dataU, isLoading: isLoadingU, isError: isErrorU} = useMyUser();

	

  return (
    <Dialog UNSAFE_className="threeDataDialBox">
      <Content>
        <Form
          isRequired
        >
          <TextField
            label="Team Name"
            value={teamName}
            onChange={setName}
          />
          
          <Button variant="cta" onPress={()=>{
            createTeam({teamName:teamName, orgId:dataU.orgId})
            .then(close);
          }}
          >
          Submit</Button>
        </Form>
      </Content>
    </Dialog>
  );
}
