import{
  DialogTrigger,
  Button,
  Text,
} from '@adobe/react-spectrum';
import LinkDialog from 'components/modals/LinkAsset';
import {useMyUser} from 'net/user';
import type {Room} from 'net/rooms'; 
import Link from '@spectrum-icons/workflow/Link';

interface LinkAssetProps{
  room:Room,
}
export default function LinkAsset({room}:LinkAssetProps){
  const {data, isLoading, isError} = useMyUser();
  return (
    <DialogTrigger>

      <Button variant="secondary" isQuiet>
        <Link/>
        <Text>Link More Assets</Text>
      </Button>

      {(close) =>(
        <LinkDialog 
          title={"Link asset to \"" + room.name +"\""}
          close={close}
          userid={data._id}
          roomid={room._id}
        />
      )}
    </DialogTrigger>
  );
}
