import React, { useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  ButtonGroup,
} from '@adobe/react-spectrum';
import RoomList from 'components/lists/roomList/roomlist';
import CreateRoomButton from 'components/buttons/CreateRoomButton';
interface AllRoomsPageProps{
  userName: any,
  userEmail: any,
  userRole: any,
  userId: any,
  userOrgId: any,
}

export default function AllRoomsPage() {
  
  return (
    <Flex direction="column" flex="4" gap="size-100">
      <Heading level={1}>Spaces</Heading>
      <ButtonGroup>
        <CreateRoomButton/>
      </ButtonGroup>
      <RoomList/>
    </Flex>
  );
}
