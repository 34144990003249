/**
 * show assets associated with a given room
 */
import{
  StatusLight,
  Well,
  Button, ButtonGroup,
  View,
  Flex,
  Text,
  Heading,
}from '@adobe/react-spectrum';
import React from 'react';
import OpenIn from '@spectrum-icons/workflow/OpenIn';
import UnlinkAssetButton from 'components/buttons/UnlinkAssetButton';
import {FileTypeIcon, isImage} from 'aesthetic/filetypeicon';
import {useMyAssetsTwo, Asset, getRoomAssetUrl} from 'net/assets';
import {Room} from 'net/rooms';
import {AssetThumbnail} from 'components/lists/MyAssetsList/MyAssetsList';
import LoadingWellList from 'components/lists/Loading';

/**
 * List assets associated with a given room
 * unfortunately the api responses are different enough i could 
 * not reuse roomlist well
 */
interface AssetListForRoomProps{
  room:Room,
  teamid:any,
}

export default function AssetListForRoom({room, teamid}:AssetListForRoomProps){
  let assetObject:any;
  let setAssObj:any;
  [assetObject, setAssObj] = React.useState();
  const useThisAsset = useMyAssetsTwo(teamid, room._id)

  React.useEffect(() => {
    useThisAsset
    .then(response => {return response})
    .then((data:any) => {
      setAssObj(data);
    });
  }, []);

  console.log(assetObject);
  
  return (
    <>
      {
        assetObject && assetObject.data.length > 0
        ? (assetObject.data.map((asset:any) => {
            return (
              <RoomAssetSection
                key={asset.fileName}
                teamid={teamid}
                asset={asset}
                roomid={room._id}
                assetUrl={getRoomAssetUrl(room._id, asset.fileName)}
                />
            )
          })
        ) : (
          <StatusLight variant="negative">
            No assets found
          </StatusLight>
        )
      }
    </>
  )
}

interface RoomAssetSectionProps{
  asset:Asset,
  assetUrl:string,
  roomid:string,
  teamid:string,
}

function RoomAssetSection({asset, assetUrl, roomid, teamid}:RoomAssetSectionProps){
  let photo;
  if(isImage(asset.fileName)){
    photo = <AssetThumbnail assetUrl={assetUrl}/>
  }

  return (
    
    <Well key={asset.fileName} width="size-6000">
      <Flex gap="size-100" alignItems="center">
        <Heading level={4} flex="2">{asset.fileName}</Heading>
        {/* <View flex="0.1">
          <FileTypeIcon filename={asset.fileName}/>
        </View> */}
        
        {/* <View flex="1">
          {photo}
        </View> */}
        <ButtonGroup flex="1">
          <View marginEnd="10px">
            <UnlinkAssetButton teamid={teamid} fileName={asset.fileName} roomid={roomid}/>
          </View>
          {/* <Button
            variant="primary"
            onPress={()=>{window.open(assetUrl)}}
            >
            <OpenIn/>
          </Button> */}
        </ButtonGroup>
      </Flex>
    </Well>


  );
}
