/**
 * Given a files name as a prop, determine an icon to show
 * representing the type of file it is based on extension
 */
import Image from '@spectrum-icons/workflow/Image';
import Model from '@spectrum-icons/workflow/3DMaterials';
import GenericFile from '@spectrum-icons/workflow/FileTxt';
import FileCode from '@spectrum-icons/workflow/FileCode';
import FileData from '@spectrum-icons/workflow/FileData';
import VideoOutline from '@spectrum-icons/workflow/VideoOutline';
import GlobeGrid from '@spectrum-icons/workflow/GlobeGrid';

interface FileTypeIconProps{
  filename:string
}
export function FileTypeIcon({filename}:FileTypeIconProps){
  if(isImage(filename)){
    return <Image/>;
  }
  if(isModel(filename)){
    return <Model/>
  }
  if(isData(filename)){
    return <FileData/>
  }
  if(isMarkup(filename)){
    return <FileCode/>
  }
  if(isVideo(filename)){
    return <VideoOutline/>
  }
  if(isDefault(filename)){
    return <GlobeGrid/>
  }
  return <GenericFile/>
}

export function isImage(filename:string):boolean{
  return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(filename);
}
export function isModel(filename:string):boolean{
  return (/\.(glb|gltf|stl|obj|fbx|3ds)$/i).test(filename);
}
export function isData(filename:string):boolean{
  return (/\.(json|csv|xl.)$/i).test(filename);
}
export function isMarkup(filename:string):boolean{
  return (/\.(3data|xml|html?)$/i).test(filename);
}
export function isVideo(filename:string):boolean{
  return (/\.(mpe?g|mp4|webm|ogg)$/i).test(filename);
}
export function isDefault(filename:string):boolean{
  return (/\.(rdp)$/i).test(filename)
}
