import React from 'react';
import { Image, Flex, SearchField, View, Button } from "@adobe/react-spectrum";
import GlobeGrid from "@spectrum-icons/workflow/GlobeGrid";
// import Particles from "react-particles-js";
import Particles from "react-tsparticles";
import { useNavigate as useHistory, } from "react-router-dom";
import API_SERVER from '../net/urls';
import { useMyUser, User } from 'net/user';

const particles = require('../styles/particles.json')

export default function EmailVerify() {
   const [emailVal, setEmailVal] = React.useState('');
   let history = useHistory();

   document.title = 'Portal - 3Data'

   const { data, isLoading, isError } = useMyUser();
   (window as any)._env_.TRIEDTOLOGIN = false

   const handleSubmit = (val: any) => {
      (window as any)._env_.TRIEDTOLOGIN = true
      window.location.href = API_SERVER
         + "/api/auth/loginRouterPost?email="
         + val + "&redirectTo=" + window.location.href
   }

   let windowHeight: string = (window.innerHeight - 5).toString()
   let windowWidth: string = (window.innerWidth).toString()


   if (isError && (window as any)._env_.TRIEDTOLOGIN) {
      redirectToLogin()
   }

   return (
      <View>
         <Flex direction="column" justifyContent="center" alignItems="center" flex gap="size-100" marginTop="20%">
            <View alignSelf="center">
               <img src="/logo.png" width="480" />
               <br />
               <SearchField placeholder="Enter Login or Guest Email..." onChange={setEmailVal} width="size-5000" onSubmit={handleSubmit} icon={<GlobeGrid />} isQuiet />
            </View>
            <br />
            <Button
               variant="primary"
               aria-label="Send email"
               onPress={() => {
                  handleSubmit(emailVal);
               }}
            >
               Proceed
            </Button>
         </Flex>
      </View>

   );
}

export function redirectToLogin() {
   const url = getRedirUrl();
   window.location.href = url;
}


function getRedirUrl() {
   return API_SERVER
      + "/api/auth/keycloakAuth?redirectTo="
      + window.location.href;
}