import useSWR, {mutate} from 'swr';
import API_SERVER from 'net/urls';
import {fetcher, FetchedData} from 'net/fetcher';

export interface Provision{
	name:string,
	domain:string,
	email:string,
	pass:string,
	adminName:string,
}

export function createNewOrg(body:any){
  // const {data, error}= useSWR('/api/provision/newOrg', fetcher);
	const url = `${API_SERVER}/api/provision/newOrg`;
	
  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  };

  return fetch(url, reqOptions)
  .then(()=>{
    mutate('/api/provision/newOrg');
  });
}

export interface EditRequestBody{
	orgId: string,
	teamId: string,
	filter:string,
}
