import API_SERVER from 'net/urls';
import { ClientRequest } from 'node:http';
import { EditRequestBody } from './rooms';
/**
 * the fetcher used with SWR
 * specifies credentials, but other than that a 
 * pretty straightforward request
 */
export async function fetcher<JSON = any>(
  input: RequestInfo,
): Promise<JSON> {
  return new Promise((resolve, reject)=>{
    fetch(
      API_SERVER+ input,
      {
        method:"GET",
        credentials:"include"
      }
     )
     .then(res=>{
       const data = res.json()
       if(res.ok){
         resolve(data);
       }
       //but we also should reject if network
       //succeeds but api returns >400 code
       reject(data); 
     })
     .catch(err=>{ // fetch api will error on network problems
      reject(err);
     })
  });
}

export function newFetcher<JSON = any>(
  input: RequestInfo, body: any
): Promise<any> {
  
  return new Promise((resolve, reject)=>{
    fetch(
      API_SERVER+ input,
      {
        method:"POST",
        credentials:"include",
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body)
      }
     )
     .then(res=>res.json())
     .then(jsonBody => {
        return jsonBody
      })
     .catch(err=>{ // fetch api will error on network problems
      reject(err);
     })
  });
}

export interface FetchedData<T>{
  data:T,
  isLoading: boolean,
  isError: boolean,
}

export interface FetchedNewData<T>{
  newData: T,
  newIsLoading: boolean,
  newIsError: boolean,
}
