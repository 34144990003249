import {
  Dialog,
  Content,
  ProgressBar,
} from '@adobe/react-spectrum';
import './Modals.scss';
export default function UnloadedModal(){
  return (
    <Dialog UNSAFE_className="threeDataDialBox">
      <Content>
        <ProgressBar label="Loading..." isIndeterminate/>
      </Content>
    </Dialog>
  );
}
