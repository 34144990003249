import{
  Button,
  Content,
  Dialog,
  useDialogContainer,
  Form,
  TextField
} from '@adobe/react-spectrum';
import { useState } from 'react'
import { uploadDefaultAsset } from 'net/team';
import './Modals.scss';

interface DefaultAssetFormProps{
  //pass close if non-containered,
  //otherwise use useDialogContainer
  close: ()=>void,
  title: string,
  teamId:string, //fill with existing room data
}

export function ContainedShareDialog({title, teamId}:DefaultAssetFormProps){
  const close = useDialogContainer().dismiss;
  return <DefaultAssetForm close={close} title={title} teamId={teamId}/>
}

export default function DefaultAssetForm({close,  title, teamId}:DefaultAssetFormProps){

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [guacUrl, setGuacUrl] = useState('')
  const [connectionName, setConnectionName] = useState('')
  const [connectionHost, setConnectionHost] = useState('')
  const [connectionUser, setConnectionUser] = useState('')
  const [connectionPassword, setConnectionPassword] = useState('')

  return(
    <Dialog UNSAFE_className="threeDataDialBox">
      <Content>
        <Form isRequired>
          <TextField
            label="Username"
            value={username}
            onChange={setUsername}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={setPassword}
          />
          <TextField
            label="Guacamole URL"
            value={guacUrl}
            onChange={setGuacUrl}
          />
          <TextField
            label="Connection Name"
            value={connectionName}
            onChange={setConnectionName}
          />
          <TextField
            label="Connection Host"
            value={connectionHost}
            onChange={setConnectionHost}
          />
          <TextField
            label="Connection User"
            value={connectionUser}
            onChange={setConnectionUser}
          />
          <TextField
            label="Connection Password"
            type="password"
            value={connectionPassword}
            onChange={setConnectionPassword}
          />
          <Button variant="cta" onPress={() => {
            const assetData:any = {
              'username': username,
              'password': password,
              'guacUrl': guacUrl,
              'connectionName': connectionName,
              'connectionHost': connectionHost,
              'connectionUser': connectionUser,
              'connectionPassword': connectionPassword
            }

            uploadDefaultAsset(teamId, assetData).then(() => {
              close()
            })
          }}>Submit</Button>
        </Form>
      </Content>
    </Dialog>
  )
}