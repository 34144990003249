import EditOrCreateRoomDialog from 'components/modals/EditOrCreateRoomDialog';
import {
  ActionButton,
  DialogTrigger,
  Text,
} from '@adobe/react-spectrum';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import {addNewRoom} from 'net/rooms';

export default function CreateRoomButton(){
  return (
    <DialogTrigger>

      <ActionButton>
        <AddCircle/>
        <Text>Create</Text>
      </ActionButton>
      {(close) =>(
        <EditOrCreateRoomDialog 
          title="Create New Room"
          close={close}
          onSubmit={addNewRoom}/>
      )}
    </DialogTrigger>
  );
}
