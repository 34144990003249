import {
  Flex,
  Heading,
  ButtonGroup,
} from '@adobe/react-spectrum';
import {editMyUser, User, useMyUser} from 'net/user';
import {AvatarList} from 'components/lists/AvatarList';
import CreateUserButton from 'components/buttons/CreateUserButton';
interface UserPageProps{
  userName: any,
  userEmail: any,
  userRole: any,
  userId: any,
  userOrgId: any,
}
export default function UserPage() {
  return (
    <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
      <Flex gap="size-100" direction="column">
        <Heading level={1}>Users</Heading>
        <ButtonGroup>
          <CreateUserButton/>
        </ButtonGroup>
        <AvatarList/>
      </Flex>
    </div>
  );
}
