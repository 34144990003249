import{
  ButtonGroup,
  Button,
  Content,
  Dialog,
  useDialogContainer,
  Heading,
  Text,
  Picker,
  Item,
} from '@adobe/react-spectrum';
import React from 'react';
import {Asset, useTeamAssets} from 'net/assets';
import {FileTypeIcon} from 'aesthetic/filetypeicon';
import {addLadingBill} from 'net/ladingBills';
import {useRoomWithId} from 'net/rooms';
import {depositAssetLocker} from 'net/team';
import {GetMyTeams} from 'net/teams';
import UnloadedModal from 'components/modals/Unloaded';
import './Modals.scss';
import{
	useParams
} from 'react-router-dom'
  
interface LinkDialogProps{
  //pass close if non-containered,
  //otherwise use useDialogContainer
  close?: ()=>void,
  title: string,
  roomid:string, //fill with existing room data
  userid:any,
}

export function ContainedLinkDialog({title, roomid, userid} :LinkDialogProps){
  const close = useDialogContainer().dismiss;
  return <LinkDialog close={close} title={title} userid={userid} roomid={roomid}/>
}
/**
 */
export default function LinkDialog({close, userid, title, roomid}:LinkDialogProps){
	const params = useParams() as any
	const roomData = useRoomWithId(params.roomId)
	// console.log(roomData)
  const {data: dataT, isLoading: isLoadingT, isError: isErrorT} = GetMyTeams(userid);
  const [linkWithFileName, setLinkWithFileName] = React.useState('');
  const [teamID, setTeamID] = React.useState('');
  const {data, isError, isLoading} = useTeamAssets(teamID);
  
  console.log(dataT);
  

  if(isErrorT) return <Dialog>"Error loading users for sharing"</Dialog>;
  if(isLoadingT) return <UnloadedModal />;
  
  return (
    <Dialog UNSAFE_className="threeDataDialBox">
    <Heading>{title}</Heading>
    <Content>
      <Picker
          label="Choose a team"
          items={dataT.data}
          onSelectionChange={setTeamID as any}
        >
          {(team:any) => (
            <Item key={team._id} textValue={team._id}>
              <Text>{team.teamName}</Text>
            </Item>
          )}
      </Picker>
      {
        teamID ? (
          <Picker
            label="Choose an asset"
            items={data ? data : []}
            onSelectionChange={setLinkWithFileName as any}
          >
            {(asset:Asset) => (
              <Item key={asset.name} textValue={asset.name}>
                <FileTypeIcon filename={asset.name}/>
                <Text>{asset.name}</Text>
              </Item>
            )}
          </Picker>
        ) : (<></>)
      }
    </Content>
    <ButtonGroup>
      <Button variant="secondary" onPress={close}>
        Cancel
      </Button>
      <Button variant="cta" onPress={
        ()=>{
          if(!linkWithFileName || !teamID){
            return;
          }
          depositAssetLocker(teamID, params.roomId, linkWithFileName).then(() => {
            window.location.reload();
          });
          
        }}>
        Confirm
      </Button>
    </ButtonGroup>
  </Dialog>
  );
}
