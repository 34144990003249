/**
 * generate welcome message
 */
import React from 'react';
import {
   useMatch as useRouteMatch,
   useNavigate as useHistory,
} from 'react-router-dom';
import { useMyUser } from 'net/user';
import Logo from 'aesthetic/logo';
import { View, Text, Flex, ActionButton, Item } from '@adobe/react-spectrum';
import { IconButton } from '@rmwc/icon-button';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import '@rmwc/icon-button/styles';
import './navigation/navigation.scss'
import LogOut from '@spectrum-icons/workflow/LogOut';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import User from '@spectrum-icons/workflow/User';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import '@rmwc/menu/styles';
// import {Menu, MenuTrigger} from '@adobe/react-spectrum';
import '@rmwc/menu/styles';
import API_SERVER from 'net/urls';
export default function Greeting() {
   const [open, setOpen] = React.useState(false);
   const { data, isLoading, isError } = useMyUser();

   //   const history: any = useHistory();
   const history = {
      push: useHistory(),
   }

   if (isLoading || isError) return (
      <Flex alignItems="baseline">
         <Text>Welcome back</Text>
      </Flex>
   );

   return (
      <div style={{ width: '100%' }}>
         <div
            onClick={() => {
               history.push('/')
            }}
            style={{ marginBottom: '30px', marginLeft: '20px', paddingTop: '10px', float: 'left', cursor: 'pointer' }}
         >
            <Logo />
         </div>
         <div style={{ float: 'right', paddingTop: '10px', marginRight: '20px' }}>
            <div style={{ float: 'left' }}>
               <Text>Welcome back, {data.name.split(' ')[0]}</Text>
            </div>
            <div style={{ float: 'right' }}>
               <MenuSurfaceAnchor>
                  <Menu open={open} onClose={evt => setOpen(false)} style={{ backgroundColor: '#171717', width: '200px' }}>
                     <MenuItem style={{ border: 'none', backgroundColor: 'transparent' }} className="menu-item-nav">
                        <span onClick={() => {
                           history.push('/settings')
                        }}
                           className='menu-item'
                        >
                           <User /> edit profile
                        </span>
                     </MenuItem>
                     <MenuItem style={{ border: 'none', backgroundColor: 'transparent' }} className="menu-item-nav">
                        <span onClick={() => {
                           window.location.href = API_SERVER + '/api/auth/keycloakLogout'
                        }}
                           className='menu-item'
                        >
                           <LogOut /> logout
                        </span>
                     </MenuItem>
                  </Menu>

                  <button id='nav-dropdown' onClick={evt => setOpen(!open)}>
                     <ChevronDown />
                  </button>
               </MenuSurfaceAnchor>
            </div>
            {/* <span
          style={{marginRight: '20px', cursor: 'pointer'}}
        >
          
        </span> */}
         </div>
      </div>
   )
}

// onClick={()=> {
//   window.location.href = API_SERVER+'/api/auth/keycloakLogout'
// }}
{/* <MenuSurfaceAnchor>
  <Menu open={open} onClose={evt => setOpen(false)}>
    <MenuItem>
      <span className="">edit profile</span>
    </MenuItem>
    <MenuItem>
      <span className="">Logout</span>
    </MenuItem>
  </Menu>

  <Button raised onClick={evt => setOpen(!open)}>
    <ChevronDown />
  </Button>
</MenuSurfaceAnchor> */}