import {
    Flex,
    View,
    Heading,
    ButtonGroup,
    Divider,
    Meter,
    StatusLight,
    Switch,
    Text,
		TextField,
    Form
} from '@adobe/react-spectrum';
import {Button} from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import React, {useState} from 'react';
import {createNewOrg} from 'net/provision';

interface ProvisionPageProps{
	role:string,
}

export default function ProvisionPage(props:ProvisionPageProps){
	const [name, setName] = useState('');
	const [domain, setDomain] = useState('');
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [adminName, setAdminName] = useState('');

	const formComplete = () => {
		if (name && domain && email && pass && adminName) {
			createNewOrg(
				{
					name: name,
					domain: domain,
					adminEmail: email,
					adminPassword: pass,
					adminName: adminName
				}
			);
			window.alert("Creating new user...")
			window.location.reload()
		} else {
			return (
				window.alert("Please fill all fields")
			)
		}
	};

  return (
    <Flex direction="column" gap="size-200">
			<Flex alignItems="center" gap="size-200">
				<Heading level={1}>Provision</Heading>
			</Flex>
      <div style={{backgroundColor: '#232323', paddingTop: '20px', paddingBottom: '20px', borderRadius: '20px', marginLeft: '20px', marginRight: '20px'}}>
          <Typography style={{padding: '20px'}} use="headline4">
            Create Organization
          </Typography>
        <View
          padding="size-250">
            <Flex direction="column" justifyContent="space-between">
              <div style={{marginLeft: '30%', marginRight: '30%', display: 'block'}}>
                <label style={{color:'white'}}>Organization Name</label>
                <TextField
                  label=""
                  UNSAFE_style={{width: '100%', color:'black'}}
                  value={name}
                  onChange={setName}
                />
                <br/>
                <br/>
                <label style={{color:'white'}}>Organization Domain</label>
                <TextField 
                  label=""
                  UNSAFE_style={{width: '100%', color:'black'}}
                  value={domain}
                  onChange={setDomain}
                />
                <br/>
                <br/>
                <label style={{color:'white'}}>Admin Email</label>
                <TextField 
                  label=""
                  UNSAFE_style={{width: '100%', color:'black'}}
                  value={email}
                  onChange={setEmail}
                />
                <br/>
                <br/>
                <label style={{color:'white'}}>Admin Password</label>
                <TextField
                  label=""
                  UNSAFE_style={{width: '100%', color:'black'}}
                  value={pass}
                  onChange={setPass}
                />
                <br/>
                <br/>
                <label style={{color:'white'}}>Admin Name</label>
                <TextField 
                  label=""
                  UNSAFE_style={{width: '100%', color:'black'}}
                  value={adminName}
                  onChange={setAdminName}
                />
                <br />
                <br />
                <br />
                <Button 
                  label="Submit"
                  raised
                  style={{backgroundColor: '#038C7F', width: '100%'}}
                  onClick={formComplete}
                />
              </div>
            </Flex>
        </View>
      </div>
    </Flex>
  );
}

// export function ProvisionForm(){
  
// }
  