/**
 * show everything we know about a given room
 * and offer CRUD options to change it
 */
import{
  ProgressBar,
  Heading,
  StatusLight,
  Text,
  Flex,
  Button,
  View,
}from '@adobe/react-spectrum';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import React, {useState} from 'react';
import AssetListForRoom from 'components/lists/AssetListForRoom';
import Shop from '@spectrum-icons/workflow/Shop';
import PermitList from 'components/lists/PermitList';
import ShareWithButton from 'components/buttons/ShareWithButton';
import LinkAssetButton from 'components/buttons/LinkAssetButton';
import {useMyUser} from 'net/user';
import {useRoomWithId} from 'net/rooms';
import {useTeamsWithId, GetMyTeams} from 'net/teams';
import {PLATFORM} from 'net/urls';
import EditRoomButton from 'components/buttons/EditRoomButton';      

interface RoomPageProps{
  roomid:string,
  teamid:string,
  userData:any,
}

export default function RoomPage({userData, roomid, teamid}:RoomPageProps){
  // request info about given room
  const [teamName, setTeamData] = useState('No team name');
  const {data, isLoading, isError} = useRoomWithId(roomid);
  const {data: dataT, isLoading: isLoadingT, isError: isErrorT} = GetMyTeams(userData._id);
  
  const bodyReq = {
    orgId: userData ? userData.orgId : "0",
    teamId: teamid ? teamid : "0",
    filter: "byOrgTeam"
  };
  const newData = useTeamsWithId(bodyReq)
    .then(response => {return response})
    .then(data => {
      if (data && data.data[0] && data.data[0].teamName) {
        setTeamData(data.data[0].teamName)
      }
    });

  function checkTeamName() {
    // go check API 
    const loader:any = <ProgressBar label="checking for team name" isIndeterminate/>;
    return window.setInterval(loader, 50000);
  }

  function redirectToRoom(roomid:string){
    const toOpen = (PLATFORM 
      +"/r/"
      +roomid);
    window.open(toOpen);
  }
  
  if(isLoading) return <ProgressBar label="loading room" isIndeterminate/>
  if(isError) return (
   <StatusLight variant="negative">
     Unable to load room with id {roomid}
   </StatusLight>
  );

  return (
    <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
      <Heading level={1}>Space</Heading>
      <div style={{backgroundColor: '#232323', marginLeft: '20px', marginRight: '20px', marginBottom: '20px', borderRadius: '20px', border: 'transparent', padding: '20px'}}>
        <Flex
          marginTop="size-200" 
          gap="size-200"
        >
          <Flex justifyContent="space-between" gap="size-200">
            <View>
              {/* <Shop flex="1" size="XL"/> */}
              <Heading flex="1" level={2}>{data.name}</Heading>
            </View>
            <View>
              <Button
                variant="primary"
                aria-label="Open Room in Platform"
                onPress={()=>redirectToRoom(roomid)}>
                <LinkOut/>
                <Text>Launch Space</Text>
              </Button>
            </View>
          </Flex>
          <Flex justifyContent="end" flex="1" alignSelf="center">
            <EditRoomButton room={data} />
          </Flex>
        </Flex>
        <Text>{data.description || "No description for this room"}</Text>
      </div>

      <div style={{margin: '20px'}}>
        <Flex gap="size-100" alignItems="baseline">
          <Heading flex="1" level={4}>Users with access</Heading>
          <Flex flex="1" justifyContent="end">
          </Flex>
        </Flex>
        <PermitList userid={userData._id} roomid={data._id}/>
      </div>

      <div style={{margin: '20px'}}>
        <Heading flex="1" level={4}>Asset(s)</Heading>
        <div style={{backgroundColor: '#232323', borderRadius: '20px', border: 'transparent', padding: '20px'}}>
          <Flex gap="size-100" alignItems="baseline">
            <Flex flex="1" justifyContent="end">
              <LinkAssetButton room={data}/>
            </Flex>
          </Flex>
          <AssetListForRoom room={data} teamid={teamid} />
        </div>
      </div>

      <div style={{margin: '20px'}}>
        <Heading flex="1" level={4}>Teams</Heading>
        <div style={{backgroundColor: '#232323', borderRadius: '20px', border: 'transparent', padding: '20px'}}>
          <Flex gap="size-100" alignItems="baseline">
            <Flex flex="1" justifyContent="end">
            </Flex>
          </Flex>
          <div>
          {
            teamNameList(dataT)
          }
          </div>
        </div>
      </div>
    </div>
  );
}

function teamNameList(data:any) {
  if (data) {
    return data.data.map((team:any) => {
      return (
        <Button
          UNSAFE_style={{marginRight: '5px'}}
          variant="primary"
          aria-label="Team Name">
          <Text>{team.teamName}</Text>
        </Button>
      )
    })
  }
  return <></>
}
