import './Avatar.scss';

/**
 * an avatar is a card with a users name & image
 */

import type {User} from 'net/user'
// import {
//   Flex,
//   Text,
//   View,
// } from '@adobe/react-spectrum';
import UserAdmin from '@spectrum-icons/workflow/UserAdmin';
import UserEdit from '@spectrum-icons/workflow/UserEdit';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
// import { Typography } from '@rmwc/typography';
import React from 'react';
import {LoadingProfilePhoto, ProfilePhoto} from 'components/profilephoto';

interface AvatarProps{
  user:User,
  children?: React.ReactNode,
  isStamped?: boolean,
}

export function LoadedAvatar({user, children, isStamped}:AvatarProps){
   let roleIcon = <UserEdit size="S"/>;
   if(user.role === "super-admin"){
     roleIcon = <UserAdmin size="S"/>;
   }
   
   return (
    //  <View
    //    borderRadius="medium"
    //    borderWidth="thin"
    //    borderColor="dark"
    //    >
    //    <Flex direction="column" alignItems="center" gap="size-10">
    //      <>
    //      {children}
    //      </>
    //      <View padding="size-150" UNSAFE_className="mainAva">
    //        <ProfilePhoto isStamped={isStamped} user={user}/>
    //      </View>
    //      <Text UNSAFE_className="avatar color-purple">{user.name}</Text>
    //      <Flex>
    //        {roleIcon} 
    //        <Text>{user.role}</Text>
    //      </Flex>
    //    </Flex>
    //  </View>

      <GridCell className="avatarBox" span={2}>

        <GridCell className='lnRt'>
          {children}
        </GridCell>
        <div className="ImageCenter">
          <ProfilePhoto isStamped={isStamped} user={user}/>
        </div>
        <div className="pdLR p2 txt-center">
          <div>{user.name}</div>
        </div>
        <div className="pdLR p2 txt-center mt m2">
        {roleIcon} 
          <div>{user.role}</div>
        </div>
      </GridCell>
   );
}

export function LoadingAvatar(){
   return (
     <Grid>
       
       <GridCell>
         <Grid >
           <LoadingProfilePhoto/>
         </Grid>
        <Grid width="size-800" height="size-200"/>
        <Grid width="size-900" height="size-200"/>
       </GridCell>
     </Grid>
   );
}

export function InlineAvatar({user, children}:AvatarProps){
   // wrap in text so this functions well with pickers
   // and comboboxes
   return (
     <div>
       <div>{user.name}, {user.role}</div>
       {/* <GridCell> */}
         {/* <Grid className="ImageCenter">
           <ProfilePhoto 
             user={user}/>
         </Grid> */}
         
         {/* <div slot="description"></div> */}
         {/* <Grid>
         {children}
         </Grid> */}
       {/* </GridCell> */}
     </div>
   );
}

