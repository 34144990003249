// @ts-nocheck

import useSWR, {mutate} from 'swr';
import API_SERVER from 'net/urls';
import {newFetcher, FetchedNewData, fetcher, FetchedData} from 'net/fetcher';

export interface Team{
  _id:string,
  teamName:string,
  ordId:string,
  teamData:any,
  data:any,
}

export function useAllTeams():FetchedNewData<Team>{
  const {data, error}= useSWR('/api/teams', newFetcher);
  return {
    newData:data,
    newIsLoading: !error && !data,
    newIsError: error,
  }
}

export function GetMyTeams(userid:string):FetchedData<Team>{
  const {data, error} = useSWR( `/api/teams/${userid}/getUserTeams`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export interface EditRequestBody{
  orgId: string,
  teamId: string,
  filter:string,
}

export async function useTeamsWithId(body:any): Promise<any>{
  const url = `${API_SERVER}/api/teams/getTeams`;
  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return await fetch(url, reqOptions)
    .then(res=>res.json())
    .then(jsonBody => {
        return jsonBody
    })
    .catch(err=>{ // fetch api will error on network problems
        console.log(err);
    })
}

export async function sendEmail(body:any): Promise<any>{
  const url = `${API_SERVER}/api/teams/sendMail`;
  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return await fetch(url, reqOptions)
    .then(res=>res.json())
    .then(jsonBody => {
        return jsonBody
    })
    .catch(err=>{ // fetch api will error on network problems
        console.log(err);
    })
}
