/**
 * format the sidebar
 */
import Logo from 'aesthetic/logo';
import{ Flex, View} from '@adobe/react-spectrum';
import {useMyUser} from 'net/user';
import NavBar from 'components/navigation/navigation';
import {MyAvatar} from 'components/lists/AvatarList';
import  '@rmwc/drawer/styles';
import{ Drawer, DrawerHeader, DrawerSubtitle, DrawerTitle } from '@rmwc/drawer';

export default function SideBar(){
  const {data, isLoading, isError} = useMyUser();
  
  return (
    <Flex
      direction="column"
      gap="size-100"
      alignItems="center"
      justifyContent="space-between"
      >
     
      {/* <View flex="1">
        <Logo/>
      </View>

      <View flex="2" minWidth="100%">
        <NavBar/>
      </View> */}

      <Drawer className="t3dataNav">
        {
          data.role === 'standard-user' ? <></> : <NavBar/>
        }
      </Drawer>  

      {/* <View flex="1">
        <MyAvatar/>
      </View>   */}
    </Flex>
    );
}
