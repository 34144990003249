import CreateUserDialog from 'components/modals/CreateUserDialog';
import {
  ActionButton,
  DialogTrigger,
  Text,
} from '@adobe/react-spectrum';
import UserAdd from '@spectrum-icons/workflow/UserAdd';

export default function CreateRoomButton(){
  return (
    <DialogTrigger isDismissable>

      <ActionButton>
        <UserAdd/>
        <Text>Add user</Text>
      </ActionButton>
      {(close) =>(
        <CreateUserDialog 
          close={close}
        />
      )}
    </DialogTrigger>
  );
}
