// @ts-nocheck

import useSWR, {mutate} from 'swr';
import API_SERVER from 'net/urls';
import {fetcher, FetchedData} from 'net/fetcher';

export interface Room{
  _id:string,
  name:string,
  access:string,
  server:string,
  description:string,
  scene:string,
  teamId:string,
  orgId:string,
  // permitInfo:{
  //   _id:string,
  //   roomId: string,
  //   userId: string,
  //   accessLevel: string,
  // }
}

export function useRoomWithId(id:string):FetchedData<Room>{
  const {data, error} = useSWR('/api/rooms/'+id, fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useRooms():FetchedData<Room[]>{
  const {data, error}= useSWR('/api/rooms', fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useMyRooms():FetchedData<Room[]>{
  const {data, error}= useSWR('/api/me/rooms', fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function addNewRoom(body:EditRequestBody){
  const url = API_SERVER + '/api/me/rooms/';
  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate('/api/rooms');
    mutate('/api/me/rooms');
  });
}

export function updateRoom(roomid:string, body:EditRequestBody){
  const url = getRoomUrl(roomid);
  const reqOptions:RequestInit = {
    method: 'PUT',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate('/api/rooms');
    mutate('/api/me/rooms');
    mutate(`/api/rooms/${roomid}`);
  });
}

export interface EditRequestBody{
  name: string,
  description: string,
  access:string,
  teamId:string,
}

export function deleteRoomById(id:string){
  const url = getRoomUrl(id);
  const options:RequestInit = {
    method:'DELETE',
    credentials:'include'
  }
  return fetch(url, options)
  .then(()=>{
    // mark as invalid for swr
    mutate('/api/rooms');
    mutate('/api/me/rooms');
  })
}

function getRoomUrl(id:string){
  return (API_SERVER
    + '/api/rooms/'
    + id);
}
