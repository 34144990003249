/**
 * change logo to light or dark
 * based on hook for current color theme
 * (otherwise itll be low contrast)
 */
// import {Image} from '@adobe/react-spectrum';
import DarkLogo from 'img/logo-dark.png';
import LightLogo from 'img/logo.png';
import {useCurrentColorScheme} from 'aesthetic/ColorSwitcher'; 

export default function Logo(){
  const colorScheme = useCurrentColorScheme();
  const logo = colorScheme === "light" ? DarkLogo : LightLogo;
  return (
    // <Image
    //   height="size-2000"
    //   width="size-1700"
    //   src={logo}
    //   alt="3data"
    // />
    <img src={logo} alt="3data" className="img-responsive" width="100px"/>
  );
}
