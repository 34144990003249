
import{
	StatusLight,
	Button,
	Text,
	Divider,
}from '@adobe/react-spectrum'
import React from 'react';
import SENDGRID from 'net/urls';
import sgMail from '@sendgrid/mail';
import {MyRoomList} from 'components/MainPageComponents/RoomList/RoomList';
import SpaceDetails from 'components/MainPageComponents/Details/SpaceDetails';
import CreateEvent from 'components/MainPageComponents/Details/CreateEvent';
import CreateCalEventButton from 'components/buttons/CreateCalEventButton';
import {useMyUser} from 'net/user';
import {getCalAuthUrl, GetCalendar, GetToken} from 'net/calendar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import '@rmwc/list/styles';
import '@rmwc/image-list/styles';
import { Typography } from '@rmwc/typography';
import Exposure from '@spectrum-icons/workflow/Exposure';
import Download from '@spectrum-icons/workflow/Download';
// import Calendar from '@spectrum-icons/workflow/Calendar';
// import Calendar from 'react-calendar';
import Calendar from 'components/MainPageComponents/Calendar/Calendar';
import CalList from 'components/MainPageComponents/Calendar/CalList';
import UpcomingMeetings from 'components/MainPageComponents/UpcomingMeetings/UpcomingMeetings';
import 'react-calendar/dist/Calendar.css';

sgMail.setApiKey(SENDGRID);

interface MainPageProps{
  userName: any,
  userEmail: any,
  userRole: any,
  userId: any,
  userOrgId: any,
}

export default function Home() {
  const {data: dataU, isLoading: isLoadingU, isError: isErrorU} = useMyUser();

  const [display, setDisplay] = React.useState(false);
  const [details, setDetails] = React.useState('');

  console.log(getCalAuthUrl(dataU._id));
  return (
    <>
      <div style={{width: '100%'}}>
        {/* <PageHeader pageTitle="Home" /> */}
        <Grid style={{padding: 0, margin: 0}}>
          <GridCell span={8}>
            <Typography style={{padding: '20px'}} use="headline4">
              {dataU.name}'s Space
            </Typography>
            <div style={{backgroundColor: "#232323", borderRadius: "15px", marginLeft: "20px", marginTop: "20px"}}>
              <MyRoomList handleDetails={setDetails} handleDisplay={setDisplay} shouldDisplay={display} details={details} />
              <div style={{display: display ? 'inline-block' : 'none', width: '100%'}}>
                {
                  details === 'spaceDetails' ? (
                    <SpaceDetails userData={dataU} />
                  ) : (
                    <CreateEvent userData={dataU} />
                  )
                }
              </div>
            </div>
            <br />
            <br />
            {/* show this button if there's no token */}
            <div style={{backgroundColor: "#232323", borderRadius: "15px", marginLeft: "20px", marginTop: "20px", marginBottom: "20px", padding: "20px"}}>
              <Calendar userId={dataU._id} />
              <br />
              <Button
                variant="primary"
                aria-label="Send email"
                onPress={() => {
                  window.location.href = getCalAuthUrl(dataU._id)
                }}
              >
                <Exposure /> Calendar Sync
              </Button>
            </div>
          </GridCell>
          <GridCell style={{marginBottom: "40px"}} span={4}>
            <Typography style={{padding: '15px', paddingLeft: '0'}} use="headline4">
              Upcoming Meetings
            </Typography>
            <div style={{backgroundColor: "#232323", height: "100%", borderRadius: "15px", marginLeft: "0", marginTop: "20px", marginRight: "20px"}}>
              <div style={{marginBottom: "40px"}}>
                <UpcomingMeetings userData={dataU} />
              </div>
              <div>
                <br />
                <br />
              </div>
            </div>
          </GridCell>
        </Grid>
      </div>
    </>
  );
}
