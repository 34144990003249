import {
  ActionButton,
  DialogTrigger,
  Text,
} from '@adobe/react-spectrum';
import ViewTeamsDialog from 'components/modals/ViewTeamsDialog';
import UserActivity from '@spectrum-icons/workflow/UserActivity';

export default function ViewTeamsButton(){
  return (
    <DialogTrigger isDismissable>
      <ActionButton UNSAFE_className="BoxButton">
        <UserActivity/>
        <br />
        <Text>View Teams</Text>
      </ActionButton>
      {(close) =>(
        <ViewTeamsDialog
          close={close}
        />
      )}
    </DialogTrigger>
  );
}
