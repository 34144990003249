/**
 * list rooms i own or rooms i'm shared with
 */
import React from 'react';
import{
	StatusLight,
	Button,
	Text,
	Divider,
	Content,
	Heading
}from '@adobe/react-spectrum';
import {PLATFORM} from 'net/urls';
import {TextField} from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import DatePicker from "react-datepicker";
import './Details.scss';
import "react-datepicker/dist/react-datepicker.css";
import {GetCalendar, CreateCalendarEvents} from 'net/calendar'

interface CreateEventProps{
	userData:any,
}

export default function CreateEvent({userData}:CreateEventProps){
	const {data, isLoading, isError} = GetCalendar(userData._id);

	const location = `${PLATFORM}/r/${userData._id}`;
	const [open, setOpen] = React.useState(false);
	const [title, setTitle] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [start_time, setStartTime] = React.useState(new Date());
	const [end_time, setEndTime] = React.useState(new Date());

	function formatParticipants(participants:any) {
		let newArr:any = [];
		if (participants.length > 0) {
			let arr = participants.split(',');
			arr.map((email:string) => {
				newArr.push({email: email.trim()})
			})
			return newArr
		}
		return newArr
	}
	function validateEmail() {
		let arr = email.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let newArr:any = [];
		arr.map((email:any) => {
			newArr.push(re.test(String(email).toLowerCase()));
		})
		if (newArr.includes(false)) {
			return false
		}
		return true
	}

	return(
		<div className="event-main-container">
      		<Heading level={4}>Schedule Meeting</Heading>
			<div className="event-sub-container">
				<div>
					<Content>Title:</Content>
					<input
						value={title}
						onChange={(evt:any) => {
							setTitle(evt.target.value)
						}}
						className="cal-text"
					/>
					<div>
						<Content>Details:</Content>
						<textarea
							value={description}
							onChange={(evt:any) => {
								setDescription(evt.target.value)
							}}
							className="cal-text"
							id="description"
						/>
					</div>
					<div>
						<Content>Invitees: <span className="subtext">separated by comma</span></Content>
						<input
							value={email}
							onChange={(evt:any) => {
								setEmail(evt.target.value)
							}}
							className="cal-text"
						/>
					</div>
				</div>
				<div style={{display: 'flex', width: '100%', paddingLeft: 'auto'}}>
					
				</div>
				<div>
					<div>
						<Content>From:</Content>
						<DatePicker
							selected={start_time}
							onChange={(date:any) => setStartTime(date)}
							showTimeSelect
							timeIntervals={15}
							dateFormat="MMMM d, yyyy h:mm aa"
							className="cal-datepicker"
						/>
					</div>
					<div>
						<Content>To:</Content>
						<DatePicker
							selected={end_time}
							onChange={(date:any) => setEndTime(date)}
							showTimeSelect
							timeIntervals={15}
							dateFormat="MMMM d, yyyy h:mm aa"
							className="cal-datepicker"
						/>
					</div>
				</div>
			</div>
			
                <br />
                <Button variant="primary" onPress={()=>{
                    if (!title || !start_time || !end_time) {
                        alert('Please fill out all form fields')
						return
                    } else {
                        CreateCalendarEvents(userData._id, data ? data[0].calendar_id : '0', {
                            title: title,
                            description: description,
                            start_time: start_time,
                            end_time: end_time,
                            participants: formatParticipants(email),
                            location: location,
                        });
						window.location.reload();
                    } 
                }}>
                Schedule</Button>
		</div>
	);
}