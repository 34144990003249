// @ts-nocheck

import useSWR, {mutate} from 'swr';
import API_SERVER from 'net/urls';
import {fetcher, FetchedData, FetchedNewData, newFetcher} from 'net/fetcher';

export interface Calendar{
  _id:string,
  userId:string,
  id:any,
  calendarToken:string,
  calId:any,
  dataG:dataG,
  successful:any,
  data:string,
  calendar_id:string,
}

export interface dataG {
    successful:boolean,
    errorMsg:string,
    debug:string,
    data:any,
}

export function GetCalendar(userId:any):FetchedData<Calendar[]>{
    const {data:cal, error:err}= useSWR(`/api/calendar/auth/getCalendar/${userId}`, fetcher);
    const {data, error}= useSWR(() => `/api/calendar/auth/getCalendarEvents/${userId}/${cal[0].id}`, fetcher);
    
    return {
        data:data,
        isLoading: !error && !data,
        isError: error,
    }
}

export async function CreateCalendarEvents(userId:any, calId:any, body:any):Promise<any>{
    const url = `${API_SERVER}/api/calendar/auth/createEvent/${userId}/${calId}`;
    const reqOptions:RequestInit = {
        method: 'POST',
        headers:{
        'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    }
    return await fetch(url, reqOptions)
        .then(res=>res.json())
        .then(jsonBody => {
            return jsonBody
        })
        .catch(err=>{ // fetch api will error on network problems
            console.log(err);
        })
}

export function getCalAuthUrl(userId:any){
    return API_SERVER
    +`/api/calendar/auth?userId=${userId}`;
}

export function GetToken(userId:any):FetchedData<Calendar>{
    const {data, error}= useSWR(`/api/calendar/auth/getToken/${userId}`, fetcher);
    return {
      data:data,
      isLoading: !error && !data,
      isError: error,
    }
}


