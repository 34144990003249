/**
 * list rooms i own or rooms i'm shared with
 */
import React from 'react';
import { useMyRooms, useRooms, Room } from 'net/rooms'
import {
   StatusLight,
   Button,
   Text,
   Divider,
} from '@adobe/react-spectrum'

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import copy from 'copy-to-clipboard';
import CalendarAdd from '@spectrum-icons/workflow/CalendarAdd';
import SendEmailButton from 'components/buttons/SendEmailButton';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import '@rmwc/snackbar/styles';
import '@rmwc/grid/styles';
import '@rmwc/list/styles';
import './roomList.scss';
import '@rmwc/data-table/styles';
import CreateCalEventButton from 'components/buttons/CreateCalEventButton';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import Maximize from '@spectrum-icons/workflow/Maximize';
import GlobeOutline from '@spectrum-icons/workflow/GlobeOutline';
import UserLock from '@spectrum-icons/workflow/UserLock';
import Copy from '@spectrum-icons/workflow/Copy';
import Messenger from '@spectrum-icons/workflow/Messenger';
import { Link } from 'react-router-dom';
import LoadingWellList from 'components/lists/Loading';
import { PLATFORM } from 'net/urls';
import { sendEmail } from 'net/teams';
import { useMyUser } from 'net/user';
import DataUnavailable from '@spectrum-icons/workflow/DataUnavailable';

interface MyRoomProps {
   handleDetails: any,
   handleDisplay: any,
   shouldDisplay: any,
   details: string,
}
export function MyRoomList({ handleDetails, handleDisplay, shouldDisplay, details }: MyRoomProps) {
   const { data, isLoading, isError } = useMyRooms();
   const { data: dataU, isLoading: isLoadingU, isError: isErrorU } = useMyUser();

   const [open, setOpen] = React.useState(false);
   let icon;

   if (isLoading) return <LoadingWellList n={5} />;

   if (isError) return (
      <StatusLight variant="negative">Error loading rooms</StatusLight>);
   return (
      <DataTable
         style={{ width: "100%", background: "transparent", border: "0px solid white" }}
      >
         <DataTableContent style={{ marginBottom: '10px' }}>
            <DataTableHead>
               <DataTableRow>
                  <DataTableHeadCell style={{ color: "white", font: "bold 15px Arial, sans-serif" }}>Schedule Meeting</DataTableHeadCell>
                  <DataTableHeadCell style={{ color: "white", font: "bold 15px Arial, sans-serif" }}>Space Assets</DataTableHeadCell>
                  <DataTableHeadCell style={{ color: "white", font: "bold 15px Arial, sans-serif" }}>Copy Link</DataTableHeadCell>
                  <DataTableHeadCell style={{ color: "white", font: "bold 15px Arial, sans-serif" }}>Send Invite</DataTableHeadCell>
                  <DataTableHeadCell style={{ color: "white", font: "bold 15px Arial, sans-serif" }}>Launch Space</DataTableHeadCell>
               </DataTableRow>
            </DataTableHead>
            <DataTableBody>
               <DataTableRow>
                  <DataTableCell style={{ color: "white" }}>
                     {/* <CreateCalEventButton userId={dataU._id} /> */}
                     <Button
                        variant="primary"
                        aria-label="Open Room in Platform"
                        UNSAFE_style={{ backgroundColor: details === 'calendarEvent' && shouldDisplay ? 'black' : '' }}
                        onPress={() => {
                           handleDetails('calendarEvent')
                           handleDisplay(!shouldDisplay)
                        }}>
                        <CalendarAdd />
                     </Button>
                  </DataTableCell>
                  <DataTableCell>
                     <Button
                        variant='overBackground'
                        UNSAFE_style={{ backgroundColor: details === 'spaceDetails' && shouldDisplay ? 'black' : '' }}
                        onPress={() => {
                           handleDetails('spaceDetails')
                           handleDisplay(!shouldDisplay)
                        }}
                     >
                        <Maximize size="S" />
                     </Button>
                  </DataTableCell>
                  <DataTableCell>
                     <Snackbar
                        open={open}
                        onClose={evt => setOpen(false)}
                        message="Space link copied to clipboard"
                        dismissesOnAction
                        action={
                           <SnackbarAction
                              label="Dismiss"
                              style={{ color: '#49FFC0' }}
                           />
                        }
                     />
                     <Button
                        variant="primary"
                        aria-label="Open Room in Platform"
                        onPress={() => {
                           if (data && data[0]) {
                              setOpen(true);
                              copyToClipboard(data[0])
                           }
                        }}>
                        <Copy />
                     </Button>
                  </DataTableCell>
                  <DataTableCell>
                     <Snackbar
                        open={open}
                        onClose={evt => setOpen(false)}
                        message="Space link copied to clipboard"
                        dismissesOnAction
                        action={
                           <SnackbarAction
                              label="Dismiss"
                              style={{ color: '#49FFC0' }}
                           />
                        }
                     />
                     <SendEmailButton nameSender={dataU.name} spaceUrl={data && data[0] ? `${PLATFORM}/r/${data[0]._id}` : ''} />
                  </DataTableCell>
                  <DataTableCell>
                     <Button
                        variant="primary"
                        aria-label="Open Room in Platform"
                        UNSAFE_style={{ backgroundColor: '#038C7F', width: '50%' }}
                        onPress={() => redirectToRoom(data[0])}>
                        <LinkOut />
                     </Button>
                  </DataTableCell>
               </DataTableRow>
            </DataTableBody>
         </DataTableContent>
      </DataTable>
   );
}

interface RoomProps {
   room: Room,
   userName: string,
   userId: string,
}


function RoomSection({ room, userName, userId }: RoomProps) {
   const [open, setOpen] = React.useState(false);
   let icon;
   if (room.access === "private") { icon = <UserLock /> }
   else { icon = <GlobeOutline /> }

   return (
      <DataTableRow>
         <DataTableCell style={{ color: "white" }}>
            <CreateCalEventButton userId={userId} />
         </DataTableCell>
         <DataTableCell>
            <Link to={'/r/' + room._id} className="mr m4">
               <Button variant='overBackground'>
                  <Maximize size="S" />
               </Button>
            </Link>
         </DataTableCell>
         <DataTableCell>
            <Snackbar
               open={open}
               onClose={evt => setOpen(false)}
               message="Space link copied to clipboard"
               dismissesOnAction
               action={
                  <SnackbarAction
                     label="Dismiss"
                     style={{ color: '#49FFC0' }}
                  />
               }
            />
            <Button
               variant="primary"
               aria-label="Open Room in Platform"
               onPress={() => {
                  setOpen(true);
                  copyToClipboard(room)
               }}>
               <Copy />
            </Button>
         </DataTableCell>
         <DataTableCell>
            <Snackbar
               open={open}
               onClose={evt => setOpen(false)}
               message="Space link copied to clipboard"
               dismissesOnAction
               action={
                  <SnackbarAction
                     label="Dismiss"
                     style={{ color: '#49FFC0' }}
                  />
               }
            />
            <SendEmailButton nameSender={userName} spaceUrl={`${PLATFORM}/r/${room._id}`} />
         </DataTableCell>
         <DataTableCell>
            <Button
               variant="primary"
               aria-label="Open Room in Platform"
               onPress={() => redirectToRoom(room)}>
               <LinkOut />
            </Button>
         </DataTableCell>
      </DataTableRow>
   );
}



// ###TODO: determine logic for opening rooms
function redirectToRoom(room: Room) {
   const toOpen = (PLATFORM
      + "/r/"
      + room._id);
   window.open(toOpen);
}
function copyToClipboard(room: Room) {
   const toOpen = (PLATFORM
      + "/r/"
      + room._id);
   copy(toOpen);
}
function sendInvite() {
   let msg = {
      recipient: 'arum@3data.io',
      subject: '3Data Meeting Invitation',
      emailBody: 'Email from within loki main',
      htmlBody: '<strong>Email from within loki main</strong>',
   }
   sendEmail(msg);
}