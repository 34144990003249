import AddUserToTeam from 'components/modals/AddUserToTeam';
import {
  ActionButton,
  DialogTrigger,
  Text,
} from '@adobe/react-spectrum';
import UserAdd from '@spectrum-icons/workflow/UserAdd';

export default function AddUserToTeamButton(){
  return (
    <DialogTrigger isDismissable>

      <ActionButton UNSAFE_className="BoxButton">
        <UserAdd/>
        <Text>Add User To Team</Text>
      </ActionButton>
      {(close) =>(
        <AddUserToTeam
					title = "Add User To Team"
					roomid = "123" 
          close={close}
        />
      )}
    </DialogTrigger>
  );
}
