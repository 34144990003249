import DefaultProfileImage from 'img/default-profile.png';
import API_SERVER from 'net/urls';

import {Avatar} from '@rmwc/avatar';

import {useMyUser, User} from 'net/user'

interface DimProps{
  width?:string,
  height?:string,
}

export function LoadingProfilePhoto({width, height}:DimProps){
  return (

    <Avatar
      src={DefaultProfileImage}
      size="small"
      name="new user"
      className="ananUser"
    ></Avatar>
  );
}

interface ProfileProps{
  user:User,
  isStamped?:boolean, // add timestamp to circumvent caching of img 
}

//does not handle loading or error logic
export function ProfilePhoto({user, width, height, isStamped}:ProfileProps & DimProps){

  let picUrl = API_SERVER + "/api/users/" + user._id + "/image";

  if(!user.image){
    picUrl = DefaultProfileImage;
  }
  return (
     <Avatar
      src={isStamped ? picUrl + "?" + Date.now() : picUrl}
      size="xsmall"
      name={user.name}
      className="userProPic"
    />
  );
}


export function MyProfilePhoto({width, height}:DimProps){
   const {data, isLoading, isError} = useMyUser();
   
   if(isLoading || isError) return(
     <LoadingProfilePhoto 
       width={width}
       height={height}
     />
   );
   
   return (
     <ProfilePhoto
      user={data}
      isStamped
      width={width}
      height={height}
     />
   );
}
