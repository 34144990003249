/**
 * list all permits associated with a given room
 */
import {
  View,
  StatusLight,
  Text,
  Grid,
  repeat,
} from '@adobe/react-spectrum'
import {usePermits, Permit} from 'net/permits';
import DeletePermitButton from 'components/modals/DeletePermitButton';
import {LoadedAvatar} from 'components/Avatar'
import {LoadingAvatarGrid} from 'components/lists/AvatarList';

interface PermitListProps{
  roomid:string,
  userid:string,
}

export default function PermitList({roomid, userid}:PermitListProps){
  const {data, isError, isLoading} = usePermits(roomid);
  
  if(isLoading) return <LoadingAvatarGrid n={3}/>;

  if(isError) return (
    <StatusLight variant="negative">
     Error loading permits
    </StatusLight>
  );
  
  return (
  <Grid
    columns={repeat('auto-fit', 'size-2000')}
    autoRows="size-4000"
    gap="size-100">
    {
      data.map((permit:Permit)=>(
      <LoadedAvatar key={permit._id} user={permit.userInfo[0]}>
        {userid === permit.userId ? (
          <></>
        ) : (
          <View alignSelf="flex-end">
            <DeletePermitButton roomid={roomid} permitid={permit._id}/>
          </View>
        )}
        <Text>{permit.accessLevel}</Text>
      </LoadedAvatar>
    ))
    }
  </Grid>
  );
}
