import {
  ActionButton,
  Text,
} from '@adobe/react-spectrum';
import Unlink from '@spectrum-icons/workflow/Unlink';
import {unlinkAsset} from 'net/assets';
interface UnlinkAssetButtonProps{
  fileName:string,
  roomid:string,
  teamid:string,
}
export default function UnlinkAssetButton(props:UnlinkAssetButtonProps){
  return (
    <ActionButton
      onPress={()=>{
        unlinkAsset(props.teamid, props.roomid, props.fileName).then(() => {
          window.location.reload();
        });
      }}
    >
      <Unlink/>
      <Text>Unlink Asset</Text>
    </ActionButton>
  );
}

