import React from 'react';
import {
    TextField,
    Form,
    Dialog,
    Content,
    Divider,
    RadioGroup,
    Heading,
    StatusLight,
    Radio,
    Text,
    Button,
  } from '@adobe/react-spectrum';
import DeleteOutline from '@spectrum-icons/workflow/DeleteOutline';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import '@rmwc/grid/styles';
import {useTeamAssets} from 'net/assets';
import {deleteAssetFromTeam} from 'net/team';

interface ViewTeamAssets{
	teamid:any,
}

export default function AssetListForTeams({teamid}:ViewTeamAssets) {
    const [assetData, setAssetData] = React.useState();
	const {data, isLoading, isError} = useTeamAssets(teamid);
    
    if (isLoading) { return <><Text> Loading...</Text></>}
    if (isError) { return <><StatusLight variant="negative"> No assets for this team</StatusLight></>}

    return (
        <>
            <Heading>Assets</Heading>
            <Grid>
                <GridRow>
                    {data.map((asset:any) => {
                        return (
                            <>
                                <GridCell>{asset.name}</GridCell>
                                <br />
                                <GridCell
                                    onClick={() => {
                                        deleteAssetFromTeam(teamid, asset.name).then(() => {
                                            window.location.reload();
                                        })
                                    }
                                }
                                ><DeleteOutline /></GridCell>
                                <br />
                            </>
                        )
                    })}
                </GridRow>
            </Grid>
        </>
    )
}