import {Button, IllustratedMessage, Heading, Content} from '@adobe/react-spectrum';
import {GetCalendar} from 'net/calendar'
import {DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell} from '@rmwc/data-table';
import {List, ListItem } from '@rmwc/list';
import {Card, CardPrimaryAction, CardMedia, CardActions, CardActionButton, CardActionIcon, CardActionIcons, CardActionButtons} from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import Clock from '@spectrum-icons/workflow/Clock';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import Calendar from '@spectrum-icons/workflow/Calendar';
import './Calendar.scss'

interface CalUser{
	userId:any,
}
export default function EventBuilder({userId}:CalUser) {
    const {data, isLoading, isError} = GetCalendar(userId);
    
    if (eventBuilder(data).length > 0) {
        return (
            eventBuilder(data).map((event:any, index:any) => {
                if (index < 3) {
                    return (
                        <Card className='upcoming-meetings-card'>
                            <CardPrimaryAction>
                                <div style={{ padding: '0 1rem 1rem 1rem' }}>
                                    <Typography use="headline6" tag="h2">
                                        <Clock /> {event.time}
                                    </Typography>
                                    <Typography
                                        use="subtitle2"
                                        tag="div"
                                        theme="textSecondaryOnBackground"
                                        style={{ marginTop: '-1rem' }}
                                    >
                                        {event.when}
                                    </Typography>
                                    <Typography
                                        use="subtitle2"
                                        tag="h4"
                                        theme="textSecondaryOnBackground"
                                        style={{marginBottom: '30px'}}
                                    >
                                        {event.title}
                                    </Typography>
                                    {/* <Typography
                                        use="caption"
                                    >
                                        {event.description}
                                    </Typography> */}
                                    <Typography
                                        use="subtitle2"
                                        tag="h5"
                                        theme="textSecondaryOnBackground"
                                    >
                                        Meeting Owner: {event.owner}
                                    </Typography>
                                    <Typography
                                        use="caption"
                                        style={{ marginTop: '-1rem', fontSize: '10px' }}
                                    >
                                        {
                                            event.who.length === 1 ? (`There is ${event.who.length} person confirmed for this meeting`) : (`There are ${event.who.length} people confirmed for this meeting`)
                                        }
                                    </Typography>
                                </div>
                            </CardPrimaryAction>
                            {
                                event.where ? (
                                    <CardActions>
                                        <CardActionButtons onClick={() => {
                                            window.open(event.location, "_blank")
                                        }}
                                            className="launch-button-cont"
                                        >
                                            <button className="launch-button"><LinkOut/> Launch Space</button>
                                        </CardActionButtons>
                                    </CardActions>
                                ) : (
                                    <CardActions>
                                        <CardActionButtons className="launch-button-cont">
                                            <button className="launch-button" id="no-launch"><LinkOut/> Launch Space</button>
                                        </CardActionButtons>
                                    </CardActions>
                                )
                            }
                        </Card>
                    )
                }
            })
        )
    }

    return (
        <Card style={{ width: '90%', border: 'none', paddingTop: '5px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '10px', backgroundImage: 'linear-gradient(#171717, #232323)'}}>
            <CardPrimaryAction>
                <div style={{ padding: '0 1rem 1rem 1rem' }}>
                    <IllustratedMessage>
                        <Calendar />
                        <Heading>No meetings found</Heading>
                        <Content>Make sure you've synced your calendar</Content>
                    </IllustratedMessage>
                </div>
            </CardPrimaryAction>
        </Card>
    )
}

function eventBuilder(events:any) {
    let arr:any = [];
    let currDate = new Date();

    // return the event with the title and time formatted appropriately
        // only show dates that are today or in the future
    
    if (events && events.length > 0) {
        events.map((event:any) => {
            if (currDate <= reformatDate(event.when.start_time)) {
                if (event.when.object === 'timespan') {
                    let obj = {
                        title: event.title,
                        time: formatAMPM(reformatDate(event.when.start_time)),
                        when: `${getDayOfWeek(reformatDate(event.when.start_time))}, ${getTheMonth(reformatDate(event.when.start_time))} ${getTheDate(reformatDate(event.when.start_time))}`,
                        where: event.location,
                        who: event.participants,
                        owner: event.owner,
                        description: event.description,
                    }
                    arr.push(obj);
                }
                if (event.when.object === 'date') {
                    let obj = {
                        title: event.title,
                        time: 'No start time',
                        when: `${getDayOfWeek(new Date(event.when.date))}, ${getTheMonth(reformatDate(event.when.date))} ${getTheDate(reformatDate(event.when.date))}`,
                        where: event.location,
                        who: event.participants,
                        owner: event.owner,
                        description: event.description,
                    }
                    arr.push(obj);
                }
            }
        })
    }

    return arr;
}

function reformatDate(elDate:any) {
    let date = new Date(elDate * 1000);
    // let formattedDate = date.toString().split(' ')

    return date;
}

function formatAMPM(date:any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function getDayOfWeek(date:any) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let day = date.getDay();
    return daysOfWeek[day];
}

function getTheMonth(date:any) {
    const month = ["January","February","March","April","May","June","July",
            "August","September","October","November","December"];
    let day = date.getMonth();
    return month[day];
}

function getTheDate(date:any) {
    let day = date.getDate();
    return day
}