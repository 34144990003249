/** 
 * Based on
 * github.com/adobe/react-spectrum/blob/main/packages/dev/docs/src/ThemeSwitcher.js
 * A button for changing color themes
 */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Light from '@spectrum-icons/workflow/Light';
import Moon from '@spectrum-icons/workflow/Moon';
import {
  ActionButton,
  Text
} from '@adobe/react-spectrum';

export function useCurrentColorScheme() {
  const mq = useMemo(() => window.matchMedia('(prefers-color-scheme: dark)'), []);
  const getCurrentColorScheme = useCallback(() => 
    // TODO: when we enable light mode, the line below should be changed to this --> localStorage.theme || (mq.matches ? 'dark' : 'light'), [mq]
    localStorage.theme || (mq.matches ? 'dark' : 'dark'), [mq]
  );
  const [colorScheme, setColorScheme] = useState(() => getCurrentColorScheme());

  useEffect(() => {
    const onChange = () => {
      setColorScheme(getCurrentColorScheme());
    };

    mq.addListener(onChange);
    window.addEventListener('storage', onChange);
    return () => {
      mq.removeListener(onChange);
      window.removeEventListener('storage', onChange);
    };
  }, [getCurrentColorScheme, mq]);

  return colorScheme;
}

interface ThemeSwitcherProps{
  children?:string
}
export function ThemeSwitcher({children}:ThemeSwitcherProps) {
  const colorScheme = useCurrentColorScheme();
  const onPress = () => {
    localStorage.theme = (colorScheme === 'dark' ? 'light' : 'dark');
    window.dispatchEvent(new Event('storage'));
  };
  const label = colorScheme === 'dark' ? 'Switch to light theme' : 'Switch to dark theme';

  return (
    <div title={label} role="presentation">
      <ActionButton
        aria-label={label}
        onPress={onPress}>
        {colorScheme === 'dark' ? <Light /> : <Moon />}
        {children && <Text>{children}</Text>}
      </ActionButton>
    </div>
  );
}

