import CreateTeamDialog from 'components/modals/CreateTeamDialog';
import {
  ActionButton,
  DialogTrigger,
  Text,
} from '@adobe/react-spectrum';
import UsersAdd from '@spectrum-icons/workflow/UsersAdd';

export default function CreateTeamButton(){
  return (
    <DialogTrigger isDismissable>

      <ActionButton UNSAFE_className="BoxButton">
        <UsersAdd/>
        <Text>Add Team</Text>
      </ActionButton>
      {(close) =>(
        <CreateTeamDialog 
          close={close}
        />
      )}
    </DialogTrigger>
  );
}
