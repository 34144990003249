import React from 'react';
import {
	TextField,
	Form,
	Dialog,
	Content,
	RadioGroup,
	Radio,
	Text,
} from '@adobe/react-spectrum';
import {Button} from '@rmwc/button';
import {sendEmail} from 'net/teams';

interface SendEmailDialogProps{
	close:()=>void,
	nameSender:string,
	spaceUrl:string,
}
  
export default function SendEmailDialog({close, nameSender, spaceUrl}:SendEmailDialogProps){
	const [email, setEmail] = React.useState('');
	return (
		<Dialog>
			<Content>
				<Form
					isRequired
				>
					<TextField
						label="Invitee Email"
						value={email}
						onChange={setEmail}
					/>
					<Button 
						label="Send Invite"
						raised
						style={{backgroundColor: '#038C7F', width: '100%'}}
						onClick={() => {
							sendInvite(email, nameSender, spaceUrl);
							close();
						}}
					/>
				</Form>
			</Content>
		</Dialog>
	);
}

function sendInvite(email:any, nameSender:string, spaceurl:string) {
	if (validateEmail(email)) {
		let msg = {
			recipient: email,
			subject: '3Data Meeting Invitation',
			emailBody: 'Email from within loki main',
			nameSender: nameSender,
			spaceurl: spaceurl,
		}
		sendEmail(msg);
		window.alert('Sent Invite');
	} else {
		window.alert('Please enter a valid email address');
	}
}

function validateEmail(email:any) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}
  