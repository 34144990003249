// @ts-nocheck

import useSWR, {mutate} from 'swr';
import API_SERVER from 'net/urls';
import {newFetcher, FetchedNewData, fetcher, FetchedData} from 'net/fetcher';

export interface User{
  _id:string,
  name:string,
  email:string,
  // password:string, //probably shouldn't use this
  endpoint:string,
  role:string,
  orgId: string,
  image?:string,
  teams:Array<string>,
  teamId:string,
  teamName:string,
  userData:any,
	allowedAssets: any,
  data:any
  // location:string,
}
// export interface SingleUser{
//   teamId:string,
//   teamName:string,
//   // location:string,
// }

export function useMyUser():FetchedData<User>{
  const {data, error}= useSWR('/api/me', fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useTeams():FetchedData<User[]>{
  const {data, error}= useSWR('/api/me', fetcher);
  return {
    data:data.teams,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useOrgs():FetchedData<User[]>{
  const {data, error}= useSWR('/api/me', fetcher);
  return {
    data:data.orgId,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useAllUsers():FetchedData<User[]>{
  const {data, error} = useSWR('/api/users', fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function createUser(body:any){
  const url = `${API_SERVER}/api/auth/register`;

  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
	.then(res=>res.json())
  .then(jsonBody => {
    	mutate(`/api/users`);
      return jsonBody
  })	
}
export function deleteUserById(id:string){
  const url = `${API_SERVER}/api/users/${id}`;
  const options:RequestInit = {
    method:'DELETE',
    credentials:'include'
  }
  return fetch(url, options)
  .then(()=>{
    mutate('/api/users');
  })
}

export function editMyUser(body:any){
  const url = `${API_SERVER}/api/me`;

  const reqOptions:RequestInit = {
    method: 'PUT',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate('/api/me');
  });
}
export function editUser(id:string,body:any){
  const url = `${API_SERVER}/api/users/${id}`;

  const reqOptions:RequestInit = {
    method: 'PUT',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate(`/api/users/${id}`);
  });
}

export function uploadProfilePhoto(file:File){
  const formdata = new FormData();
  //profile photo is just an asset upload,
  //so i name it to avoid collisions
  formdata.append("", file, "3DataProfilePhoto_"+file.name);

  const requestOptions:RequestInit = {
    method: 'POST',
    body: formdata,
    credentials: 'include'
  };

  const url = `${API_SERVER}/api/me/image`;

  return fetch(url, requestOptions)
  .then(()=>{
    mutate('/api/me'); //mark cache invalid
		window.location.reload()
  })

}
