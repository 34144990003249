/**
 * list rooms i own or rooms i'm shared with
 */
import React from 'react';
import{
    StatusLight,
    Button,
    Text,
    Divider,
}from '@adobe/react-spectrum'
import CalList from 'components/MainPageComponents/Calendar/CalList';
import {DataTable, DataTableRow, DataTableHead, DataTableBody, DataTableHeadCell, DataTableCell, DataTableContent} from '@rmwc/data-table';
import '@rmwc/data-table/styles';

interface UpcomingMeetingsProps{
    userData:any,
}

export default function UpcomingMeetings({userData}:UpcomingMeetingsProps){
    const [open, setOpen] = React.useState(false);

    return(
        <div style={{paddingTop: '20px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px'}}>
            <CalList userId={userData._id} />
        </div>
    );
}