/**
 * The pretty-much-top-level application
 * This file handles all routing, including
 * redirecting to login
 *
 * for the navbar, see components/navbar.tsx
 */
import {
   BrowserRouter as Router,
   // Switch,
   Routes as Switch,
   Route,
} from "react-router-dom";

import React, { Fragment } from 'react';

import EmailVerify from 'pages/EmailRedir'
import SidebarLayout from 'components/layout/SidebarLayout';
import DefLayout from 'components/layout/DefLayout';
import AssetPage from 'pages/assets';
import AllRoomsPage from 'pages/AllRooms';
import RoomInfoWithId from 'pages/r/RoomInfoWithId';
import UserPage from 'pages/users';
import TeamPage from 'pages/Teams';
import SettingsPage from 'pages/Settings';
import ProvisionPage from 'pages/Provision';
import HomePage from 'pages/main';

import { useMyUser, User } from 'net/user';
import API_SERVER from 'net/urls';
import { log } from "node:console";

export default function App() {
   const { data, isLoading, isError } = useMyUser();

   if (!data) {
      return (

         <Router>
            <Fragment>
               <DefLayout>
                  <Switch>
                     <Route path="/" element={
                        <EmailVerify />
                     }>
                     </Route>
                  </Switch>
               </DefLayout>
            </Fragment>
         </Router>

      );
   } else {

      const { name, email, role, id, orgId } = defineData(data);

      return (

         <Router>
            <Fragment>
               <SidebarLayout>
                  <Switch>
                     <Route path="/settings" element={
                        <SettingsPage />
                     }>
                     </Route>
                     <Route path="/spaces" element={
                        <AllRoomsPage />
                     }>
                     </Route>
                     <Route path="/r/:roomId" element={
                        <RoomInfoWithId />
                     }>
                        {/* 
                since room is passed by id, title setting
                is deferred until we request rooms/id
                and get the name
                */}
                     </Route>
                     <Route path="/users" element={
                        <UserPage />
                     }>
                     </Route>
                     <Route path="/teams" element={
                        <TeamPage userName={name} userEmail={email} userRole={role} userId={id} userOrgId={orgId} />
                     }>
                     </Route>
                     <Route path="/provision" element={
                        <ProvisionPage role={role} />
                     }>
                     </Route>
                     <Route path="/" element={
                        <HomePage />
                     }>
                     </Route>
                     <Route path="/home" element={
                        <HomePage />
                     }>
                     </Route>
                  </Switch>
               </SidebarLayout>
            </Fragment>
         </Router>
      );
   }
}

export function redirectToLogin() {
   const url = getRedirUrl();
   window.location.href = url;
}


function getRedirUrl() {
   return API_SERVER
      + "/api/auth/keycloakAuth?redirectTo="
      + window.location.href;
}

function defineData(somedata: User): RefinedUserData {
   if (somedata) {
      return {
         name: somedata.name,
         email: somedata.email,
         role: somedata.role,
         id: somedata._id,
         orgId: somedata.orgId,
      }
   } else {
      redirectToLogin()
      return {
         name: "test",
         email: "test@3data.io",
         role: "super-admin",
         id: "asdfasdfasdfasdfdsf",
         orgId: "asdfasdfasdfasdfdsf",
      }
   }

}

export interface RefinedUserData {
   name: string,
   email: string,
   role: string,
   id: string,
   orgId: string,
}
