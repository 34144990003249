// @ts-nocheck

import {fetcher, FetchedData} from 'net/fetcher';
import API_SERVER from 'net/urls';
import {User} from 'net/user';
import useSWR, {mutate} from 'swr';

export interface Permit{
  _id: string,
  roomId:string,
  userId:string,
  accessLevel:string,
  userInfo:User[],
}

export function usePermits(roomid:string):FetchedData<Permit[]>{
  const {data, error} = useSWR( `/api/rooms/${roomid}/permits`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export interface EditPermitsRequestBody{
  roomId:string,
  userId:string,
  accessLevel: string,
}

export function addPermit(roomid:string, body:EditPermitsRequestBody){
  const url = getPermitUrl(roomid);
  const reqOptions:RequestInit = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
  return fetch(url, reqOptions)
  .then(()=>{
    mutate(`/api/rooms/${roomid}/permits`);
  });
}

export function deletePermit(roomid:string, permitid:string){
  const url = getPermitUrl(roomid) + "/" + permitid;
  const options:RequestInit = {
    method:'DELETE',
    credentials:'include'
  }
  return fetch(url, options)
  .then(()=>{
    mutate(`/api/rooms/${roomid}/permits`);
  })
}

function getPermitUrl(roomid:string){
  return API_SERVER
  +`/api/rooms/${roomid}/permits`;
}
