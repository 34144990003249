/**
 * List all assets associated with my user
 */
import {useMyAssets, Asset, getMyAssetUrl} from 'net/assets'
import {useRoomWithId} from 'net/rooms';
import{
  StatusLight,
  Well,
  Button, ButtonGroup,
  View,
  Flex,
  Text,
  Divider,
  Heading,
  Image,
}from '@adobe/react-spectrum'
import OpenIn from '@spectrum-icons/workflow/OpenIn';
import {List, ListItem } from '@rmwc/list';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import '@rmwc/grid/styles';
import '@rmwc/list/styles';

import {FileTypeIcon, isImage} from 'aesthetic/filetypeicon';
import DeleteAssetButton from 'components/modals/DeleteAssetButton';
import LoadingWellList from 'components/lists/Loading';

import{
	useParams
} from 'react-router-dom'

/**
 * List my assets
 */

export default function MyAssetsList(){
  const params = useParams() as any
	const roomData = useRoomWithId(params.roomId)

	const {data, isLoading, isError} = useMyAssets(roomData.data.teamId,params.roomId);

  if(isLoading) return <LoadingWellList n={10}/>;
   
  if(isError) return (
   <StatusLight variant="negative">
     Error loading assets
   </StatusLight>
  );
  return(
    <Grid className="section">
      <GridCell span={12}>
        <List>
    {
      data.map((asset:Asset)=>(
        <AssetSection key={asset.name} asset={asset}/>
      ))
    }
        </List>
      </GridCell>
    </Grid>
  );
}

interface AssetSectionProps{
  asset:Asset
}

function AssetSection({asset}:AssetSectionProps){
  const assetUrl = getMyAssetUrl(asset.name);

  let photo;
  if(isImage(asset.name)){
    photo = <AssetThumbnail assetUrl={assetUrl}/>
  }

  return (
    // <Well key={asset.name}>
    //   <Flex gap="size-100" alignItems="center">
    //     <Heading level={4} flex="2">{asset.name}</Heading>
    //     <View flex="0.1">
    //       <FileTypeIcon filename={asset.name}/>
    //     </View>
    //     <Divider size="S" orientation="vertical" />
    //     <Text flex="1">{formatBytes(asset.size)}</Text>
    //     <View flex="1"></View>
        
    //     <View flex="1">
    //       {/*if available, put a thumbnail*/} 
    //       {photo}
    //     </View>
    //     <ButtonGroup flex="1">
    //       <View marginEnd="10px">
    //         <DeleteAssetButton filename={asset.name} assetUrl={assetUrl}/>
    //       </View>
    //       <Button
    //         variant="primary"
    //         onPress={()=>{window.open(assetUrl)}}
    //         >
    //         <OpenIn/>
    //       </Button>
    //     </ButtonGroup>
    //   </Flex>
    // </Well>

    <ListItem>
          <GridRow className="w-100">
            <GridCell span={4}>
            <h4>{asset.name}</h4>
            </GridCell>
            <GridCell span={1}>
              <FileTypeIcon filename={asset.name}/>
            </GridCell>
            <GridCell span={2}>
              <Divider size="S" orientation="vertical" />
              <Text flex="1">{formatBytes(asset.size)}</Text>
            </GridCell>
            <GridCell span={3}>
              {/*if available, put a thumbnail*/} 
              {photo}
            </GridCell>
            <GridCell span={2}>

              <DeleteAssetButton filename={asset.name} assetUrl={assetUrl}/>  

              <Button
                variant="primary"
                onPress={()=>{window.open(assetUrl)}}>
                <OpenIn/>
              </Button>

            </GridCell>
          </GridRow>
        </ListItem>
  );
}

interface AssetThumbnailProps{
  assetUrl:string,
}

export function AssetThumbnail({assetUrl}:AssetThumbnailProps){
  return (<Image 
           src={assetUrl}
           alt="asset thumbnail"
           height="size-600"
           width="size-600"
           object-fit="scale-down"/>);
}


export function formatBytes(bytes:number, decimals = 0) {
    if (bytes === 0) return '0 bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes/Math.pow(k,i)).toFixed(dm))+' '+sizes[i];
}
