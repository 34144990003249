import EditOrCreateRoomDialog from 'components/modals/EditOrCreateRoomDialog';
import {
  Button,
  DialogTrigger,
  Text
} from '@adobe/react-spectrum';
import Edit from '@spectrum-icons/workflow/Edit';
import {updateRoom, EditRequestBody, Room} from 'net/rooms';

interface EditRoomButtonProps{
  room:Room
}
export default function EditRoomButton({room}:EditRoomButtonProps){
  
  return (
    <DialogTrigger>

      <Button variant="secondary" isQuiet>
        <Edit/>
        <Text>Edit Info</Text>
      </Button>
      {(close) =>(
        <EditOrCreateRoomDialog 
          title="Create New Room"
          close={close}
          room={room}
          onSubmit={(body:EditRequestBody)=>{
            return updateRoom(room._id, body);
          }}
          />
      )}
    </DialogTrigger>
  );
}
