import{
  ButtonGroup,
  Button,
  Content,
  Dialog,
  useDialogContainer,
  Heading,
  Picker,
  Item,
	Text,
	ActionButton,
  DialogTrigger
} from '@adobe/react-spectrum';
import {InlineAvatar} from 'components/Avatar';
import React from 'react';
import type {User} from 'net/user';
import {addPermit} from 'net/permits';
import {useMyUser, useAllUsers} from 'net/user';
import {addUserToTeam} from 'net/team';
import UnloadedModal from 'components/modals/Unloaded';
import {useTeams} from 'net/user';
import {uploadAsset} from 'net/team';
import ArrowUp from '@spectrum-icons/workflow/ArrowUp';
import Add from '@spectrum-icons/workflow/Add';
import './Modals.scss';
import DefaultAssetForm from './DefaultAssetForm';
  
interface AddAssetToTeamProps{
  //pass close if non-containered,
  //otherwise use useDialogContainer
  close?: ()=>void,
  title: string,
  roomid:string, //fill with existing room data
}

export function ContainedShareDialog({title, roomid}:AddAssetToTeamProps){
  const close = useDialogContainer().dismiss;
  return <AddAssetToTeam close={close} title={title} roomid={roomid}/>

}
/**
 */
export default function AddAssetToTeam({close,  title, roomid}:AddAssetToTeamProps){
  const {data, isError, isLoading} = useAllUsers();
  const teamsData = useTeams();
  const [shareWithId, setShareWithId] = React.useState('');
  const [statusMsg, setStatusMsg] = React.useState('');
  const [access, setAccess] = React.useState('viewer');
  const [teamName, setTeamName] = React.useState('');
  const [shareTeamId, setShareTeamId] = React.useState('');
	const { data: data2, isError: isError2, isLoading: isLoading2} = useMyUser();

	const fileInput:any = React.createRef();
  const selectFile = ()=>{
    fileInput.current.click();
  };

  const uploadFiles = ()=>{
    setStatusMsg("Loading...")
		let errors = ""
		let uploadSuccessful = true
    const files = fileInput.current.files;
    for(let i = 0; i < files.length; i++){
      uploadAsset(shareTeamId,files[i]).then( (response) => {
				if(!response.success){
					if(i === files.length - 1){
						errors += `${response.error}`
					} else {
						errors += `${response.error},`
					}
					uploadSuccessful = false
				}
			}).then( () => {
				if( i === files.length -1){
					if(uploadSuccessful){
						setStatusMsg("Upload Successful!")
					} else {
						setStatusMsg(errors)
					}
				}
			})
    }
	
  }

	function renderMsg(msgStr:any){

		var msgArr = msgStr.split(',')

		return msgArr.map( (obj:any) => {
			return <li><Text>{obj}</Text></li>
		});

	}

	function renderAssetUploads(){
		
		if(data2.allowedAssets.includes('rdp')){
      console.log('DATA2:', data2);
      
			return <DialogTrigger isDismissable>
				
        <ActionButton>
          <Add/>
          <Text>Add RDP Session</Text>
          {/* <input
            style={{display: 'none'}}
            type="file"
            ref={fileInput}
            onChange={uploadFiles}
            multiple
          /> */}
        </ActionButton>
        {(close => (
          <DefaultAssetForm
            title = "Add Asset To Team"
            teamId={shareTeamId} 
            close={close}
          />
        ))}
      </DialogTrigger>
		}
		
	}
	
	if(isError) return <Dialog>"Error loading users for sharing"</Dialog>;
  if(isLoading) return <UnloadedModal />;

  return (
    <Dialog UNSAFE_className="threeDataDialBox">
    <Heading>{title}</Heading>
    <Content>
				<Picker
          label="Team ID"
          items={teamsData.data}
          onSelectionChange={setShareTeamId as any}
        >
          {(user:User) => (
            <Item key={user.teamId} textValue={user.teamName}>
              <Text>{user.teamName}</Text>
            </Item>
          )}
        </Picker>
				
				<ActionButton onPressStart={selectFile}>
        <ArrowUp/>
        <Text>Upload</Text>
        <input
          style={{display: 'none'}}
          type="file"
          ref={fileInput}
          onChange={uploadFiles}
          multiple
        />
      </ActionButton>
     	
			{renderAssetUploads()}
 
			<br/>
			<br/>
			<ul style={{ listStyleType: "none" }}>
				{renderMsg(statusMsg)}
			</ul>
    </Content>
    
  </Dialog>
  );
}
