import {
  Header,
  Provider,
  Flex,
  View,
  Divider,
} from '@adobe/react-spectrum';
import React from 'react';
import {useMyUser} from 'net/user';
import Logo from 'aesthetic/logo';
import SideBar from 'components/sidebar';
import {ThemeSwitcher, useCurrentColorScheme} from 'aesthetic/ColorSwitcher';
import LogoutButton from 'components/buttons/LogoutButton';
import Greeting from 'components/greeting';
//import {testTheme} from 'aesthetic/test-theme';
import {T3DataStyles} from 'aesthetic/T3DataStyles';
import PropertiesCopy from '@spectrum-icons/workflow/PropertiesCopy';
interface SidebarLayoutProps{
  children:React.ReactNode,
}

export default function SidebarLayout(props:SidebarLayoutProps){
    const colorScheme = useCurrentColorScheme();
    const {data, isLoading, isError} = useMyUser();

    return (
    <>
      <Provider theme={T3DataStyles} colorScheme={colorScheme} minHeight="100%">
        <div style={{backgroundColor: '#171717', overflow: 'hidden', width: "100%", display: "flex", flexDirection: "row"}}>
          {
            data.role === 'super-admin' || data.role === 'local-admin' ? (
              <>
                <div className="innerSpace">
                  <div>
                    <Greeting />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="sideNav">
                    <SideBar />
                  </div>
                  
                  {props.children}
                </div>
              </>
            ) : (
              <>
                <div className="innerSpace">
                  <div>
                    <Greeting />
                    <br />
                    <br />
                    <br />
                    <br />
                    {props.children}
                  </div>
                  
                </div>
              </>
            )
          }
        </div>
        
      </Provider>
    </>
  );
}
