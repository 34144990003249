// @ts-ignore
import React from 'react';
import SendEmailDialog from 'components/modals/SendEmailDialog';
import {
   ActionButton,
   DialogTrigger,
   Text,
   Button,
} from '@adobe/react-spectrum';
import Messenger from '@spectrum-icons/workflow/Messenger';

interface SendEmailProps {
   nameSender: string,
   spaceUrl: string,
}


export default function SendEmailButton({ nameSender, spaceUrl }: SendEmailProps) {
   return (
      <DialogTrigger isDismissable>
         <ActionButton
            UNSAFE_style={{
               backgroundColor: 'transparent',
               borderColor: 'transparent'
            }}
         >
            <Button
               variant="primary"
               aria-label="Send Invite"
               isDisabled={true}
               UNSAFE_style={{
                  backgroundColor: 'transparent',
                  border: '2px solid black'
               }}
            >
               <Messenger />
            </Button>
         </ActionButton>
         {(close) => (
            <SendEmailDialog
               nameSender={nameSender}
               spaceUrl={spaceUrl}
               close={close}
            />
         )}
      </DialogTrigger>
   );
}
