import {
    Header,
    Provider,
    Flex,
    View,
  } from '@adobe/react-spectrum';
  import React from 'react';
  import SideBar from 'components/sidebar';
  import {ThemeSwitcher, useCurrentColorScheme} from 'aesthetic/ColorSwitcher';
  import LogoutButton from 'components/buttons/LogoutButton';
  //import {testTheme} from 'aesthetic/test-theme';
  import {T3DataStyles} from 'aesthetic/T3DataStyles';
  import 'aesthetic/3dataCSS/_main.scss';
  import PropertiesCopy from '@spectrum-icons/workflow/PropertiesCopy';
  interface DefLayoutProps{
    children:React.ReactNode,
  }
  export default function DefLayout(props:DefLayoutProps){
      const colorScheme = useCurrentColorScheme();
  
      return (
      <>
        <Provider theme={T3DataStyles} colorScheme={colorScheme} minHeight="100%">
          <Flex
            direction="row"
            gap="size-100"
            alignContent="center"
            justifyContent="center"
            >
                <View>
                    {props.children}
                </View>
          </Flex>
  
        </Provider>
      </>
    );
  }
  