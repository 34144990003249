import {
    Flex,
    Heading,
    ButtonGroup,
  } from '@adobe/react-spectrum';
import TeamList from 'components/lists/TeamList/teamlist';
import CreateTeamButton from 'components/buttons/CreateTeamButton';
import {Tab, TabBar} from '@rmwc/tabs';
import '@rmwc/tabs/styles';
import ViewTeamsButton from 'components/buttons/ViewTeamsButton';
import AddUserToTeamButton from 'components/buttons/UserToTeamButton';
import AddAssetToTeamButton from 'components/buttons/AssetToTeamButton';

interface TeamPageProps{
  userName: any,
  userEmail: any,
  userRole: any,
  userId: any,
  userOrgId: any,
}

export default function TeamPage(props:TeamPageProps) {
  return (
    <Flex gap="size-100" direction="column">
      <Heading level={1}>Teams</Heading>
      <div style={{marginLeft: '25%'}}>
        <ButtonGroup>
          <CreateTeamButton/>
          <ViewTeamsButton />
        </ButtonGroup>
        <ButtonGroup>
          <AddUserToTeamButton/>
          <AddAssetToTeamButton/>
        </ButtonGroup>
      </div>
    </Flex>
  );
}
  //<TeamList userName={props.userName} userEmail={props.userEmail} userRole={props.userRole} userId={props.userId} userOrgId={props.userOrgId}/>
  
