import{
  ButtonGroup,
  Button,
  Content,
  Dialog,
  useDialogContainer,
  Heading,
  Text,
  TextField,
  TextArea,
  Flex,
  Picker,
  Item,
} from '@adobe/react-spectrum';
import React from 'react';
import type {User} from 'net/user';
import {useTeams} from 'net/user';
import {EditRequestBody, Room} from 'net/rooms'; 
import GlobeOutline from '@spectrum-icons/workflow/GlobeOutline';
import UserLock from '@spectrum-icons/workflow/UserLock';


interface RoomDialogProps{
  //pase close if non-containered,
  //otherwise use useDialogContainer
  close?: ()=>void,
  title: string,
  room?:Room, //optionally fill with existing room data
  onSubmit:(body:EditRequestBody)=>Promise<void>
}

export function ContainedEditRoomDialog({onSubmit, title, room}:RoomDialogProps){
  const close = useDialogContainer().dismiss;
  return <EditOrCreateRoomDialog close={close} onSubmit={onSubmit} title={title} room={room}/>
}
/**
 * create OR update, based on passed onSubmit
 * This is so we can use same form for both create and
 * update
 */
export default function EditOrCreateRoomDialog({close, onSubmit, title, room}:RoomDialogProps){
  const initialDescription= room ? room.description : '';
  const initialName = room ? room.name : '';
  const initialAccess = room ? room.access : 'private';

  const {data, isLoading, isError} = useTeams();
  const [name, setName] = React.useState(initialName);
  const [description, setDescription] = React.useState(initialDescription);
  const [access, setAccess] = React.useState(initialAccess);
  const [triedInvalidName, setTriedInvalidName] = React.useState(false);
  const [shareTeamId, setShareTeamId] = React.useState('');
  const [teamName, setTeamName] = React.useState('');
  const nameFilled = React.useMemo(
    ()=>name.length>0,
    [name]
  );
  
  //logic for validation warning
  // warn people only if they try something invalid
  //
  let vState:'valid'|'invalid'|undefined = undefined;
  if(nameFilled){
    vState = 'valid';
  }
  else if(triedInvalidName){
      vState='invalid';
  }
  const setTeamVars = () => {
    
  };

  return (
    <Dialog UNSAFE_className="threeDataDialBox">
    <Heading>{title}</Heading>
    <Content>
      <Flex direction="column"  gap="size-100">
        <TextField
          label="Room name"
          width="100%"
          autoFocus
          value={name}
          onChange={setName as any}
          maxLength={56} //chars
          isRequired
          validationState={vState}
        />
        <Picker
          label="Access level"
          selectedKey={access}
          onSelectionChange={setAccess as any}>
          <Item key="private" textValue="private">
            <UserLock size="S"/>
            <Text>Private</Text>
            <Text slot="description">Only you and shared users</Text>
          </Item>
          <Item key="public" textValue="public">
            <GlobeOutline size="S"/>
            <Text>Public</Text>
            <Text slot="description">Anyone with link</Text>
          </Item>
        </Picker>
        <Picker
          label="Team ID"
          items={data}
          onSelectionChange={setShareTeamId as any}
        >
          {/* <Item>
            <Text>Test</Text>
          </Item> */}
          {(user:User) => (
            <Item key={user.teamId} textValue={user.teamName}>
              <Text>{user.teamName}</Text>
            </Item>
          )}
        </Picker>
        <TextArea
          maxLength={128} //max chars
          width="100%"
          label="Description"
          value={description || ' '}
          onChange={setDescription as any}
        />
      </Flex>
    </Content>
    <ButtonGroup>
      <Button variant="secondary" onPress={close}>
        Cancel
      </Button>
      <Button variant="cta" onPress={
        ()=>{
          if(name.trim()===""){
            setTriedInvalidName(true);
            return;
          }

          onSubmit({
            name: name,
            description:description,
            access:access,
            teamId:shareTeamId,
          }).then(close);
        }}>
        Confirm
      </Button>
    </ButtonGroup>
  </Dialog>
  );
}
