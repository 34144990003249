/**
 * a generic loading list,
 * to be used with SWR to generate 
 * predictable loading behavoir
 */
import {
  Well,
  Flex,
  View,
  Heading,
  Divider,
  ProgressBar,
} from '@adobe/react-spectrum'
export function LoadingWellSection(){
  return (
    <Well>
      <Flex gap="size-100" justifyContent="space-between" alignItems="center">
        <View flex="2" height="size-200" backgroundColor="label-gray"/>
        <Divider size="S" orientation="vertical" />
        <Heading level={4} flex="2">Loading...</Heading>
        <View flex="1"/>
        <ProgressBar isIndeterminate label="Loading" flex="2"/>


        
      </Flex>
    </Well>
  );
}

interface LoadingWellListProps{
  n:number,
}
export default function LoadingWellList({n}:LoadingWellListProps){
  return(
    <Flex direction="column">
    {
      [...Array(n)].map((cv:number, i:number)=>(
        <LoadingWellSection key={i}/>
      ))
    }
    </Flex>
  );
}
