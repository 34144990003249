/**
 * display a users photo and a button that could
 * upload a new one
 */
import {MyProfilePhoto} from 'components/profilephoto';
import {
  ActionButton,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  Text,
  View,
  Content,
} from '@adobe/react-spectrum';
import {Grid, GridRow, GridCell} from '@rmwc/grid';
import '@rmwc/grid/styles';
import EditCircle from '@spectrum-icons/workflow/EditCircle';
import {uploadProfilePhoto} from 'net/user';
import {createRef} from 'react';

interface EditMyUserFormProps{
  name:string,
  role:string,
}

export default function ChangeProfilePhoto({name, role}:EditMyUserFormProps){
  const fileInput:any = createRef();
  const selectFile = ()=>{
    fileInput.current.click();
  };

  return (
    <>
      <Grid>
        {/* If you need additional control over height of your grid, or need to add SubGrids, you can add your own GridRow components. */}
        <GridRow>
          <GridCell span={4}>
            <MyProfilePhoto />
            <Button
              variant="primary"
              aria-label="Upload Profile Image"
              UNSAFE_style={{marginTop: '7px'}}
              onPress={selectFile}>
              <EditCircle size="S"/>
            </Button>
          </GridCell>
          <GridCell span={8}>
            <Heading level={3}>{name}</Heading>
            <Heading level={4}>Role:<span style={{fontStyle: 'italic', marginLeft: '10px'}}>{role}</span></Heading>
          </GridCell>
        </GridRow>
      </Grid>
      <input
        style={{display: 'none'}}
        type="file"
        accept="image/*"
        ref={fileInput}
        onChange={()=>{
          const file = fileInput.current.files[0];
          uploadProfilePhoto(file);
        }}
      />
    </>
  );
}
