import {
   ButtonGroup,
   Button,
   Content,
   Dialog,
   useDialogContainer,
   Heading,
   Picker,
   Item,
   Text
} from '@adobe/react-spectrum';
import { InlineAvatar } from 'components/Avatar';
import React from 'react';
import type { User } from 'net/user';
import { addPermit } from 'net/permits';
import { useAllUsers } from 'net/user';
import { addUserToTeam } from 'net/team';
import UnloadedModal from 'components/modals/Unloaded';
import { useTeams } from 'net/user';
import './Modals.scss';


interface AddUserToTeamProps {
   //pass close if non-containered,
   //otherwise use useDialogContainer
   close?: () => void,
   title: string,
   roomid: string, //fill with existing room data
}

export function ContainedShareDialog({ title, roomid }: AddUserToTeamProps) {
   const close = useDialogContainer().dismiss;
   return <AddUserToTeam close={close} title={title} roomid={roomid} />

}
/**
 */
export default function AddUserToTeam({ close, title, roomid }: AddUserToTeamProps) {
   const { data, isError, isLoading } = useAllUsers();
   const teamsData = useTeams();
   const [shareWithId, setShareWithId] = React.useState('');
   const [access, setAccess] = React.useState('viewer');
   const [teamName, setTeamName] = React.useState('');
   const [shareTeamId, setShareTeamId] = React.useState('');

   if (isError) return <Dialog>"Error loading users for sharing"</Dialog>;
   if (isLoading) return <UnloadedModal />;

   return (
      <Dialog UNSAFE_className="threeDataDialBox">
         <Heading>{title}</Heading>
         <Content>
            <Picker
               minWidth="40%"
               label="Choose a user"
               items={data}
               onSelectionChange={setShareWithId as any}
            >
               {(user: User) => (
                  <Item key={user._id} textValue={user.name}>
                     {user.name}
                     {/* <InlineAvatar user={user}/> */}
                  </Item>
               )}
            </Picker>

            <Picker
               label="Team ID"
               items={teamsData.data}
               onSelectionChange={setShareTeamId as any}
            >
               {(user: User) => (
                  <Item key={user.teamId} textValue={user.teamName}>
                     <Text>{user.teamName}</Text>
                  </Item>
               )}
            </Picker>
            <br />
            <br />
            <ButtonGroup>
               <Button variant="secondary" onPress={close}>
                  Cancel
               </Button>
               <Button variant="cta" onPress={
                  () => {
                     if (!shareWithId || !access) {
                        return;
                     }
                     const reqbody = {
                        teamId: shareTeamId,
                        userId: shareWithId,
                     }
                     addUserToTeam(reqbody).then(close);
                  }}>
                  Confirm
               </Button>
            </ButtonGroup>
         </Content>
      </Dialog>
   );
}
