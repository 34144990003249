import {AlertDialog} from '@adobe/react-spectrum';
import {deleteUserById, User} from 'net/user';
import './Modals.scss';
interface DeleteUserDialogProps{
  user:User,
}
export default function DeleteUserDialog({user}:DeleteUserDialogProps){
  return (
    <AlertDialog
      title={"Remove \""+user.name+"\""}
      variant="destructive"
      cancelLabel="Cancel"
      primaryActionLabel="Remove"
      UNSAFE_className="threeDataDialBox"
      onPrimaryAction={()=>
        deleteUserById(user._id)
        .catch(err=>console.error(err))
      }
      >
      Are you sure you want to delete this user?
    </AlertDialog>
  );
}
