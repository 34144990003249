/**
 * show users in the api
 * TODO: show only users in your org?
 */
import {useAllUsers, useMyUser, User} from 'net/user'
import {
  View,
  StatusLight,
  repeat,
} from '@adobe/react-spectrum';


import {Grid, GridCell, GridRow} from '@rmwc/grid';

import {LoadedAvatar, LoadingAvatar} from 'components/Avatar';
import DeleteUserButton from 'components/buttons/DeleteUserButton';

export function AvatarList(){
  const {data, isLoading, isError} = useAllUsers();
 
  if(isLoading) return <LoadingAvatarGrid n={20}/>
  if(isError) return (
    <StatusLight variant="negative">
     Error loading other users
    </StatusLight>
  );

  return(

  <Grid
  //   columns={repeat('auto-fit', 'size-2000')}
    // autoRows="size-4000"
    // gap="size-100"
    >
    {
    data.map((user:User)=>(
      <LoadedAvatar key={user._id} user={user}>
        <View alignSelf="flex-end">
          <DeleteUserButton user={user}/>
        </View>
      </LoadedAvatar>
    ))
    }
  </Grid>
  );
}

interface GridProps{
  n:number,
}
export function LoadingAvatarGrid({n}:GridProps){
  return (
  <Grid
    // columns={repeat('auto-fit', 'size-2000')}
    // autoRows="size-4000"
    // gap="size-100"
    // UNSAFE_className="spectrum-Grid"
    >

    {[...Array(n)].map((cv:any, i:number)=>(
      <LoadingAvatar key={i}/>
    ))
    }

  </Grid>
  );
}

export function MyAvatar(){
   const {data, isLoading, isError} = useMyUser();
   
   if(isLoading) return <LoadingAvatar />   
   if(isError) return (
     <StatusLight variant="negative">
       Error loading user
     </StatusLight>
   );

   return <LoadedAvatar isStamped user={data}/>
}
