/**
 * list rooms i own or rooms i'm shared with
 */
import {useMyRooms, useRooms, Room} from 'net/rooms'
import{
  StatusLight,
  Well,
  Button,
  View,
  Flex,
  Text,
  Divider,
  Content,
  Heading,
  ButtonGroup,
}from '@adobe/react-spectrum'

import {List, ListItem } from '@rmwc/list';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import '@rmwc/grid/styles';
import '@rmwc/list/styles';
import './roomList.scss';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import Maximize from '@spectrum-icons/workflow/Maximize';
import GlobeOutline from '@spectrum-icons/workflow/GlobeOutline';
import UserLock from '@spectrum-icons/workflow/UserLock';
import RoomMoreMenu from 'components/RoomMoreMenu';
import {Link} from 'react-router-dom';
import LoadingWellList from 'components/lists/Loading';
import {PLATFORM} from 'net/urls';

export default function RoomList(){
  const {data, isLoading, isError} = useRooms();

  if(isLoading) return <LoadingWellList n={10}/>;
   
  if(isError) return (
   <StatusLight variant="negative">Error my loading rooms</StatusLight>);

  return(
    <Grid className="section">
      <GridCell span={12}>
        <List>
    {
      data.map((room:Room)=>(
        <RoomSection key={room._id} room={room}/>
      ))
    }
      </List>
      </GridCell>
    </Grid>
  );
}

export function MyRoomList(){
  const {data, isLoading, isError} = useMyRooms();
  if(isLoading) return <LoadingWellList n={5}/>;
   
  if(isError) return (
   <StatusLight variant="negative">Error my loading rooms</StatusLight>);

  return(
    <Grid className="section">
      <GridCell span={12}>
        {
          data.map((room:Room)=>(
            <RoomSection key={room._id} room={room}/>
          ))
        }
    </GridCell>
    </Grid>
  );
}

interface RoomProps{
  room:Room
}


function RoomSection({room}:RoomProps){
  let icon;
  if(room.access === "private"){ icon = <UserLock/>}
  else{ icon = <GlobeOutline/>}

  return (
    // <Well>
    //   <Flex gap="size-100" justifyContent="space-between" alignItems="center">
    //     <Heading level={4} flex="2">{room.name}</Heading>
    //     <Divider size="S" orientation="vertical" />
    //     <Content flex="2">{room.description}</Content>

    //     <Flex
    //       flex="1"
    //       direction="column"
    //       alignItems="center"
    //       >
    //       {icon}
    //       <Text>{room.access}</Text>
    //     </Flex>
    //     <ButtonGroup flex="1">
    //       <View marginEnd="10px">
    //         <RoomMoreMenu room={room}/>
    //       </View>
    //       <View >
    //         <Link to={'/r/'+room._id} >
    //           <Button variant='overBackground'>
    //           <Maximize size="S"/>
    //           </Button>
    //         </Link>
    //       </View>
    //       <Button
    //         variant="primary"
    //         aria-label="Open Room in Platform"
    //         onPress={()=>redirectToRoom(room)}
    //         >
    //         <LinkOut/>
    //       </Button>
    //     </ButtonGroup>
        
    //   </Flex>
    // </Well>

    // <Grid className="section">
    //   <GridCell span={12}>
      // <List>
        <ListItem>
          <Grid className="w-100">
            <GridCell span={2}>
              {icon} <Text>{room.access}</Text>
            </GridCell>
            <GridCell span={3}>
              <Text>{room.name}</Text>
            </GridCell>
            <GridCell span={4}>
                <Link to={'/r/'+room._id} className="mr m4">
                  <Button variant='overBackground'>
                    <Maximize size="S"/>
                  </Button>
                </Link>

                <Button
                  variant="primary"
                  aria-label="Open Room in Platform"
                  onPress={()=>redirectToRoom(room)}>
                  <LinkOut/>
                  <Text>Launch Space</Text>
                </Button>
            </GridCell>
          </Grid>
        </ListItem>
      // </List>
    //   </GridCell>
    // </Grid>
  );
}



// ###TODO: determine logic for opening rooms
function redirectToRoom(room:Room){
  const toOpen = (PLATFORM 
    +"/r/"
    +room._id);
  window.open(toOpen);
}
