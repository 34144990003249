/**
 * update text fields for user collection
 */
import {
  TextField,
  ButtonGroup,
  Flex,
  ProgressBar,
  View,
  Text
} from '@adobe/react-spectrum';
import {Button} from '@rmwc/button';
import {useState, useMemo} from 'react';
import {editMyUser, User, useMyUser} from 'net/user';
interface EditMyUserFormProps{
  data:any,
  isError:boolean,
  isLoading:boolean,
}
interface EditUserFormProps{
  user:any,
}

export function EditMyUserForm({data, isError, isLoading}:EditMyUserFormProps){

  if(isError || isLoading) return <ProgressBar isIndeterminate label="loading"/>;
  return <EditUserForm user={data}/>;
}

export function EditUserForm({user}:EditUserFormProps){
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');

  const emailDomain = user.email.split('@').slice(-1)[0];
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValidEmail = () => {
    if(re.test(email)){
      //Email valid. Procees to test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
      if(email.indexOf(emailDomain, email.length - emailDomain.length) !== -1){
          //VALID
        return true
      }
      return false
    }
  };
  
  let isValidName = () => {
    if (name.length < 1 || !name) {
      return false
    }
    return true
  }

  const passWarning = useMemo(
    () => warnPassword(password), 
    [password]
  );
  const passValid= passWarning ==='';
  
  return (
    <Flex direction="column">
      <View UNSAFE_style={{paddingTop: '20px', paddingBottom: '20px', paddingLeft: '20px', marginRight: '20px'}}>
          <Flex direction="column">
            <TextField
              UNSAFE_style={{width: '100%'}}
              validationState={isValidName() ? 'valid' : 'invalid'}
              label="Name"
              value={name}
              onChange={setName}
            />
            {!isValidName() ? (
              <>
                <Text
                  UNSAFE_style={{color: 'red', fontSize: '10px'}}
                >
                You must enter a name
              </Text>
              </>
            ): (
              <></>
            )}
            <br />
            <TextField
              UNSAFE_style={{width: '100%'}}
              validationState={isValidEmail() ? 'valid' : 'invalid'}
              label="Email"
              value={email}
              onChange={setEmail}
            />
            {!isValidEmail() ? (
              <>
                <Text
                  UNSAFE_style={{color: 'red', fontSize: '10px'}}
                >
                The email address must match your company domain
              </Text>
              </>
            ): (
              <></>
            )}
            <br />
            <TextField
              UNSAFE_style={{width: '100%'}}
              validationState={passWarning==='' ? 'valid' : 'invalid'}
              label="Password"
              onChange={setPassword}
              type='password'
            />
            {passWarning!=='' ? (
              <>
                <Text
                  UNSAFE_style={{color: 'red', fontSize: '10px'}}
                >
                {passWarning}
              </Text>
              </>
            ): (
              <></>
            )}
            <br />
            <Button 
              label="Update Details"
              raised
              style={{backgroundColor: '#038C7F'}}
              onClick={()=>{
                if (password.length < 8) {
                  editMyUser({name:name,  email:email, password:password})
                } else {
                  editMyUser({name:name,  email:email})
                }
              }}
            />
          </Flex>
      </View>
    </Flex>
  );
}

export function warnPassword(password:string){
  if(password.length < 10){
    return "Must be ten characters or longer";
  }
  if(!/[a-z]/.test(password)){
    return "Must contain at least 1 lowercase letter";
  }
  if(!/[A-Z]/.test(password)){
    return "Must contain at least 1 uppercase letter";
  }
  if(!/[0-9]/.test(password)){
    return "Must contain a number";
  }
  //this'll be falsy 
  return '';
}
