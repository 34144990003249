// @ts-nocheck

import useSWR, { mutate } from 'swr';
import { fetcher, FetchedData } from 'net/fetcher';
import API_SERVER from 'net/urls';

export interface Asset {
   fileName: string,
   name: string,
   accessTime: number,
   size: number, //bytes
   _id: string,
}


export function useMyAssets(teamId: any, roomId: any): FetchedData<Asset[]> {
   const { data, error } = useSWR(`${API_SERVER}/api/teams/assetlocker/${teamId}/${roomId}/listAssets`, fetcher);
   return {
      data: data ? (data as any).files : null,
      isLoading: !error && !data,
      isError: error,
   }
}

export function useMyAssetsTwo(teamId: any, roomId: any): Promise<JSON> {
   const url = `${API_SERVER}/api/teams/assetlocker/${teamId}/${roomId}/listAssets`;
   const reqOptions: RequestInit = {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json'
      },
      credentials: 'include',
   }
   return new Promise((resolve, reject) => {
      fetch(url, reqOptions)
         .then(res => {
            const data = res.json()
            if (res.ok) {
               resolve(data);
            }
            //but we also should reject if network
            //succeeds but api returns >400 code
            reject(data);
         })
         .catch(err => { // fetch api will error on network problems
            reject(err);
         })
   });
   // return await fetch(url, reqOptions)
   //   .then(res=>res.json())
   //   .then(jsonBody => {
   //     return jsonBody
   //   })
   //   .catch(err=>{ // fetch api will error on network problems
   //       console.log(err);
   //   })
}

export function useTeamAssets(teamId: string): FetchedData<Asset[]> {
   const { data, error } = useSWR('/api/teams/' + teamId + '/listAssets', fetcher);
   return {
      data: data ? (data as any).files : null,
      isLoading: !error && !data,
      isError: error,
   }
}

export function getMyAssetUrl(assetName: string) {
   return API_SERVER
      + "/api/me/assets/"
      + assetName;
}

export function deleteAsset(assetUrl: string) {
   const options: RequestInit = {
      method: 'DELETE',
      credentials: 'include'
   }
   return fetch(assetUrl, options)
      .then(() => {
         mutate('/api/me/assets'); //mark cache invalid
         mutate(assetUrl.substring(0, assetUrl.lastIndexOf('/')));
      })
}

export function useRoomAssets(roomid: string): FetchedData<Asset[]> {
   const { data, error } = useSWR('/api/rooms/' + roomid + '/assets', fetcher);
   return {
      data:
         data
            ? data.map((asset: any) => { asset.name = asset.fileName; return asset })
            : false,
      isLoading: !error && !data,
      isError: error,
   }
}

export function getRoomAssetUrl(roomid: string, assetname: string) {
   return API_SERVER
      + "/api/rooms/" + roomid
      + "/assets/" + assetname;
}

export function uploadAsset(file: File) {
   const formdata = new FormData();
   formdata.append("", file, file.name);

   const requestOptions: RequestInit = {
      method: 'POST',
      body: formdata,
      credentials: 'include'
   };

   const url = API_SERVER + "/api/me/assets";

   return fetch(url, requestOptions)
      .then(() => {
         mutate('/api/me/assets'); //mark cache invalid
      })

}

export function unlinkAsset(teamid: string, roomid: string, filename: string) {
   const url = `${API_SERVER}/api/teams/assetlocker/${teamid}/${roomid}/${filename}/remove`;
   const options: RequestInit = {
      method: 'DELETE',
      credentials: 'include'
   }
   return fetch(url, options)
      .then(() => {
         mutate('/api/teams/assetlocker');
      })
}